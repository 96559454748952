import { AgGridReact } from "ag-grid-react";
import React from "react";

export default function MtcnDetails(props) {

    const payload = JSON.parse(props.data);

    const recordingDate = payload?.orderdet?.txninf?.recdttm !== undefined && payload?.orderdet?.txninf?.recdttm !== null
        ? new Date(payload?.orderdet?.txninf?.recdttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const recordingDateForIndex = payload?.orderdet?.txninf?.reclcldtm !== undefined && payload?.orderdet?.txninf?.reclcldtm !== null
        ? new Date(payload?.orderdet?.txninf?.reclcldtm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const recordingDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const payDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    var notifLst = payload?.orderitemlst?.[0].orderitem?.auxtxninf?.ntflst;
    const rowDataNotifLst = notifLst !== undefined && notifLst !== null ? notifLst : [];
    const colDefsNotifLst = [
        { field: 'tmpl', headerName: "Template", width: "150%" },
        { field: 'msgtyp', headerName: "Message Type", width: "150%" },
        { field: 'lang', headerName: "Language", width: "150%" },
        { field: 'msgcd', headerName: "Message Code", width: "auto" },
        { field: 'ntfid', headerName: "Notification Id", width: "auto" },
    ];

    const rowDataPrcssFlgLst = payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rfndflglst !== undefined && payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rfndflglst !== null ? payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rfndflglst : [];
    const colDefsPrcssFlgLst = [
        { field: 'typ', headerName: "Type", width: "auto" },
        { field: 'val', headerName: "Label", width: "auto" }
    ];

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            MTCN DETAILS
                        </h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        MTCN Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <span className="font-weight-bold">MTCN: </span>{payload?.orderitemlst?.[0].orderitem?.itmid}
                                            </div>
                                            <div className="col-lg-6">
                                                <span className="font-weight-bold">Transaction Category: </span>{payload?.orderitemlst?.[0].orderitem?.txncat}
                                            </div>
                                            <div className="col-lg-6">
                                                <span className="font-weight-bold">Status: </span>{payload?.orderitemlst?.[0].orderitem?.stat}
                                            </div>
                                            <div className="col-lg-6">
                                                <span className="font-weight-bold">Sub Status: </span>{payload?.orderitemlst?.[0].orderitem?.substat}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Sender
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg">
                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderdet?.sndr?.name?.full}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Sender Transaction Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Funds In: </span>{payload?.orderdet?.txninf?.fundsin}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Recording Date Time: </span>{recordingDate}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Recording Date for Index: </span>{recordingDateForIndex}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Total Gross: </span>{payload?.orderdet?.txninf?.totgross}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Channel Info
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Device Id: </span>{payload?.orderdet?.txninf?.chnl?.devid}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Name: </span>{payload?.orderdet?.txninf?.chnl?.name}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">IP Address: </span>{payload?.orderdet?.txninf?.chnl?.ipaddr}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Txn Type: </span>{payload?.orderdet?.txninf?.chnl?.typ}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Txn Sub Type: </span>{payload?.orderdet?.txninf?.chnl?.prodtyp}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Send Product Attr: </span>{payload?.orderdet?.txninf?.chnl?.sndprodattr}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Pay Product Attr: </span>{payload?.orderdet?.txninf?.chnl?.payprodattr}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Receiver
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg">
                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.name?.full}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Receiver Transaction Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Funds Out: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.fundsout}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Recording Date Time: </span>{recordingDateTime}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Pay Date Time: </span>{payDateTime}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Queue Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.quenm}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Channel Info
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Device Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.devid}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.name}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">IP Address: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.ipaddr}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Txn Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.typ}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Txn Sub Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.prodtyp}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Send Product Attr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.sndprodattr}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Pay Product Attr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.chnl?.payprodattr}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Corridor Info
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Sender Corridor Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Pricing (CPC): </span>{payload?.orderdet?.txninf?.origcorr?.cpc}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country: </span>{payload?.orderdet?.txninf?.origcorr?.iso}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country 3: </span>{payload?.orderdet?.txninf?.origcorr?.iso3}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Currency: </span>{payload?.orderdet?.txninf?.origcorr?.curr}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Country Name: </span>{payload?.orderdet?.txninf?.origcorr?.cntrynm}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">State/Province Code: </span>{payload?.orderdet?.txninf?.origcorr?.st}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Currency Name: </span>{payload?.orderdet?.txninf?.origcorr?.currnm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Destination Country
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Pricing (CPC): </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.cpc}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.iso}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country 3: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.iso3}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Currency: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.curr}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Country Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.cntrynm}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">State/Provience Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.st}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Currency Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.dest?.currnm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        IDestination Country
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Pricing (CPC): </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.cpc}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.iso}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Country 3: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.iso3}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">ISO Currency: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.curr}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Country Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.cntrynm}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">State/Provience Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.st}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Currency Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.corr?.idest?.currnm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Financial Process
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Send Principal: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.prin}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Gross Amount: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.grossamt}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Total CHG USD: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.totchgusd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Send Principal USD: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.prinusd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pay Principal USD: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.payprinusd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Payout Amount: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.payamt}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Exchange USD: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.xrusd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Receiver Total: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rcvrtot}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Send/ Rcv Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.sndrcvind}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fee PC_Key: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.feekey}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">FX_KEY: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fxkey}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Will Call Pay Principal: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcpayprin}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        FX Info Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        FX
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fx?.xr1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fx?.xr2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fx?.xf1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fx?.xf2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Effective Exchange Rate: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.fx?.effrt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        WCFX
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcfx?.xr1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcfx?.xr2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcfx?.xf1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcfx?.xf2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Effective Exchange Rate: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.wcfx?.effrt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Agent FX
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.agtfx?.xr1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Rate 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.agtfx?.xr2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.agtfx?.xf1}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Exchange Fee 2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.agtfx?.xf2}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span className="font-weight-bold">Effective Exchange Rate: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.agtfx?.effrt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Notification List
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div
                                                className="ag-theme-balham ml-3 mr-3"
                                                style={{ height: 150, width: "100%" }}
                                            >
                                                <AgGridReact
                                                    rowData={rowDataNotifLst}
                                                    columnDefs={colDefsNotifLst}
                                                    enableCellTextSelection={true}
                                                ></AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Process Flag List
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div
                                                className="ag-theme-balham ml-3 mr-3"
                                                style={{ height: 150, width: "100%" }}
                                            >
                                                <AgGridReact
                                                    rowData={rowDataPrcssFlgLst}
                                                    columnDefs={colDefsPrcssFlgLst}
                                                    enableCellTextSelection={true}
                                                ></AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
