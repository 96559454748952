import { withOktaAuth } from "@okta/okta-react";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Component } from "react";
import ReactJson from 'react-json-view';
import Header from "../../header/header";
import { getHeadersNRTCbsrch, getHeadersNRTLookup } from "../../utility/utility";

class cbviewDisplayMtcnMocnDetails extends Component {

    state = {
        mtcn: "",
        mocn: "",
        userId: "",
        isAuthorized: false,
        isMtcnFound: false,
        isLoading: true,
        jsonData: {}
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;

        try {

            const accessRole = jwt_decode(accessToken.value).appRoles;
            const id = jwt_decode(accessToken.value).hqintlId;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_MTUTILITY) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id.substring(0, id.indexOf("@")),
                });
            }
        } catch (error) {
            // invalid token format
        }

        const mtcn = this.props.location.state.mtcn ? this.props.location.state.mtcn : "";
        const mocn = this.props.location.state.mocn;

        if (mtcn !== null && mtcn !== '') {
            this.getNrtLookupDetails(mtcn);
        } else if (mocn !== null && mocn !== '') {
            this.getNrtCbsrchDetails(mocn);
        }
    }

    getNrtLookupDetails = (mtcn) => {
        axios.get(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_LOOKUP_SERVICE_URL}/v1/ordnrt/fetchorderitem/legacy?tenantId=WU&orderId=${mtcn}`,
            getHeadersNRTLookup(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            if (response.status === 200) {
                var itmid = response.data.orderitemlst?.[0].orderitem?.itmid;
                if (itmid !== undefined && itmid !== null && itmid !== '') {
                    this.setState({ mtcn: itmid });
                    this.setState({
                        jsonData: response.data,
                        isMtcnFound: true,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        jsonData: response.data,
                        isMtcnFound: false,
                        isLoading: false
                    });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getNrtCbsrchDetails = (mocn) => {
        let submittedObj = {};
        submittedObj.searchCriteriaRequest = {
            ctrlnumber: mocn
        };

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_CBSRCH_SERVICE_URL}/v1/ordnrt/items/search`,
            submittedObj,
            getHeadersNRTCbsrch(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            if (response.status === 200) {
                this.setState({ mtcn: response.data[0]['itmid'] });
                this.getNrtLookupDetails(this.state.mtcn)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    onHandleBackCbsearch = () => {
        this.props.history.push("/cbview-search-mtcn-mocn")
    }

    onHandleUICbsearch = () => {
        this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-ui", state: { mtcn: this.state.mtcn } });
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="CBVIEW"
                />

                <div className="container-fluid">
                    {(this.state.isAuthorized && !this.state.isLoading && this.state.isMtcnFound &&
                        (<div className="card-body">
                            <div>
                                <h4 className="card-title main-heading">
                                    CBVIEW
                                </h4>

                                <div className="row mb-3">
                                    <div className="col-lg-6" />
                                    <div className="col-sm-6 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-warning mar-right10"
                                            data-test="button-search"
                                            onClick={this.onHandleBackCbsearch}>
                                            BACK TO SEARCH
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                            data-test="button-search"
                                            onClick={this.onHandleUICbsearch}>
                                            UI VIEW
                                        </button>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        TRANSACTION DETAILS
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <ReactJson
                                                    src={this.state.jsonData}
                                                    name="payload"
                                                    enableClipboard={true}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    quotesOnKeys={false}
                                                    displayArrayKey={false}
                                                    indentWidth={8}
                                                    shouldCollapse={(field) => {
                                                        return field.name === "largeArray";
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}

                    {(this.state.isAuthorized && !this.state.isLoading && !this.state.isMtcnFound &&
                        <div>
                            <div className="row">
                                <div className="col-lg-4 mar-top10"></div>
                                <div className="col-lg-4 mar-top10">
                                    <div className="container-fluid alert alert-danger alert-padding">
                                        MTCN Not Found.
                                    </div>
                                </div>
                                <div className="col-lg-4 mar-top10"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        data-test="button-search"
                                        onClick={this.onHandleBackCbsearch}>
                                        BACK TO SEARCH
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withOktaAuth(cbviewDisplayMtcnMocnDetails);