import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { IconContext as IconContextMTMD } from "react-icons";
import { AiTwotoneEdit as AiTwotoneEditMTMD } from "react-icons/ai";
import Header from "../../header/header";
import { getHeadersShared2 } from "../../utility/utility";


class RefundRuleMaintenance extends Component {

    state = {
        rrtRuleNum: "",
        rrtIsoCode: "",
        rrtNaid: "",
        rrtAction: "",
        rrtTxnStatus: "",
        rrtFutureFlags: "",
        rrtChannel: "",
        rrtBrand: "",
        accessGrantedRead: false,
        accessGrantedWrite: false,
        showDeleteRecordConfirm: false,
        accessToken: "",
        ID: "",
        rows: [],
        columns: [
            {
                headerName: "Action",
                field: "value",
                cellRendererFramework: (params) => {
                    return (
                        <div
                            className="cursor-pointer text-center"
                            onClick={() => {
                                if (!this.state.accessGrantedWrite) {
                                    this.setState({
                                        submitStatusRefundDetails: 'Error',
                                        submitMsgRefundDetails: "* UPDATE NOT ALLOWED BY THIS OPERATOR *",
                                    });
                                } else {
                                    this.handleEditRefundDetails(params);
                                }
                            }}
                        >
                            <IconContextMTMD.Provider value={{ color: "#FDD017", size: "20px" }}>
                                <AiTwotoneEditMTMD />
                            </IconContextMTMD.Provider>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                floatingFilter: false,
                width: "60px",
            },
            { field: "rrtRuleNum", headerName: "rrt_rule_num", width: "145px" },
            { field: "rrtIsoCode", headerName: "rrt_iso_code", width: "145px" },
            { field: "rrtNaid", headerName: "rrt_naid", width: "145px" },
            { field: "rrtAction", headerName: "rrt_action", width: "145px" },
            { field: "rrtTxnStatus", headerName: "rrt_txn_status", width: "145px" },
            { field: "rrtFutureFlags", headerName: "rrt_future_flags", width: "145px" },
            { field: "rrtChannel", headerName: "rrt_channel", width: "145px" },
            { field: "rrtBrand", headerName: "rrt_brand", width: "145px" },
        ],
        enableBtn: false,
        gridApi: null,
        submitStatusRefundDetails: null,
        submitMsgRefundDetails: "",
        paginationValQueue: 25,
        paginationDropdown: "",
        defaultColumnDef: { resizable: true, sortable: true, filter: true, floatingFilter: true },
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplate: '<span style="">No Records Found</span>',
    }

    componentDidMount() {
        const accessToken = this.props.authState.accessToken.value;
        try {
            const id = jwt_decode(accessToken).hqintlId;
            const accessRole = jwt_decode(accessToken).appRoles;

            const accessGrantRead = accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONTRACE) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)

            const accessGrantWrite = accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)

            this.setState({
                accessToken: accessToken,
                ID: id,
                accessGrantedRead: accessGrantRead,
                accessGrantedWrite: accessGrantWrite
            });
        } catch (error) {
            //invalid token
        }
    }

    onChangeControlRefundDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onPageSizeChangedRefundDetails = (e) => {
        this.setState({ paginationValQueue: e.target.value });
        this.state.gridApi.paginationSetPageSize(Number(e.target.value));
    };

    onChangeControlRRTIsoDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlRRTNaidDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlRRTTxnStatusDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlActionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlChannelDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlBrandDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    getRRTActionsList = () => {
        let RRTActions = ["IGNR", "FINC"];
        return RRTActions.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getRRTChannelsList = () => {
        let RRTChannels = ["RT", "DG", "TM", "AL"];
        return RRTChannels.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getRRTBrandsList = () => {
        let RRTBrands = ["WU", "VG", "OV", "AL"];
        return RRTBrands.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getPaginationDropdownsList = () => {
        let paginationDropdown = [25, 50, 100, 500];
        return paginationDropdown.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    onHandleBackRefundDetails = () => {
        this.props.history.push("/tmon-maintenance-details");
    };

    onHandleClearRefundDetails = () => {
        this.setState({
            rrtRuleNum: "",
            rrtIsoCode: "",
            rrtNaid: "",
            rrtAction: "",
            rrtTxnStatus: "",
            rrtFutureFlags: "",
            rrtChannel: "",
            rrtBrand: "",
            enableBtn: false,
        });
        setTimeout(() => {
            this.setState({
                submitStatusRefundDetails: null,
                submitMsgRefundDetails: ""
            });
        }, 5000);
    };

    onGridReadyRefundDetails = (params) => {
        if (params) {
            this.setState({ gridApi: params.api });
        }
        axios
            .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/refundrules/refunds`, {
                headers: {
                    ...getHeadersShared2().headers,
                    Authorization: this.state.accessToken,
                    "userId": this.state.ID,
                },
            })
            .then((response) => {
                this.setState({
                    rows: response.data.records,
                });
            })
    };

    onHandleAddRefundDetails = () => {
        if (!this.onValidateRefundDetails()) {
            return;
        }

        let submittedObj = {};
        submittedObj.rrtIsoCode = this.state.rrtIsoCode;
        submittedObj.rrtNaid = this.state.rrtNaid;
        submittedObj.rrtAction = this.state.rrtAction;
        submittedObj.rrtTxnStatus = this.state.rrtTxnStatus;
        submittedObj.rrtChannel = this.state.rrtChannel;
        submittedObj.rrtBrand = this.state.rrtBrand;
        axios
            .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/refundrules/refund`, submittedObj, {
                headers: {
                    ...getHeadersShared2().headers,
                    Authorization: this.state.accessToken,
                    "userId": this.state.ID,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        submitStatusRefundDetails: "OK",
                        submitMsgRefundDetails: response.data.responseString
                    });
                } else {
                    this.setState({
                        submitStatusRefundDetails: "Error",
                        submitMsgRefundDetails: response.data.responseString,
                    });
                }
                this.onGridReadyRefundDetails();
                this.onHandleClearRefundDetails();
            });
    };

    handleEditRefundDetails = (params) => {
        let data = params.node.data;
        this.setState({
            rrtRuleNum: data.rrtRuleNum,
            rrtIsoCode: data.rrtIsoCode,
            rrtNaid: data.rrtNaid,
            rrtAction: data.rrtAction,
            rrtTxnStatus: data.rrtTxnStatus,
            rrtChannel: data.rrtChannel,
            rrtBrand: data.rrtBrand,
            enableBtn: true
        });
    };

    onHandleUpdateRefundDetails = () => {
        if (!this.onValidateRefundDetails()) {
            return;
        }

        let submittedObj = {};
        submittedObj.rrtRuleNum = this.state.rrtRuleNum;
        submittedObj.rrtIsoCode = this.state.rrtIsoCode;
        submittedObj.rrtNaid = this.state.rrtNaid;
        submittedObj.rrtAction = this.state.rrtAction;
        submittedObj.rrtTxnStatus = this.state.rrtTxnStatus;
        submittedObj.rrtChannel = this.state.rrtChannel;
        submittedObj.rrtBrand = this.state.rrtBrand;
        axios
            .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/refundrules/refund`, submittedObj, {
                headers: {
                    ...getHeadersShared2().headers,
                    Authorization: this.state.accessToken,
                    "userId": this.state.ID,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        submitStatusRefundDetails: "OK",
                        submitMsgRefundDetails: response.data.responseString,
                    });
                } else {
                    this.setState({
                        submitStatusRefundDetails: "Error",
                        submitMsgRefundDetails: response.data.responseString,
                    });
                }
                this.onGridReadyRefundDetails();
                this.onHandleClearRefundDetails();
            });
    };

    onValidateRefundDetails = () => {
        if (this.state.rrtIsoCode === "") {
            this.setState({
                submitStatusRefundDetails: "Error",
                submitMsgRefundDetails: "Please Enter The Value For RRT-ISO-CODE",
            });
            return false;
        }
        if (this.state.rrtAction === "") {
            this.setState({
                submitStatusRefundDetails: "Error",
                submitMsgRefundDetails: "Please Enter The Value For RRT-ACTION ",
            });
            return false;
        }
        return true;
    };

    getQueueMantenanceGroupDetailsModal = () => {
        return (
            <div>
                <Modal
                    show={this.state.showDeleteRecordConfirm}
                    animation={true}
                    size="md"
                    className="shadow-lg border"
                >
                    <Modal.Header className="bg-dark text-white text-center py-1">
                        <Modal.Title className="text-center">
                            <h5 className="modal-header-custom">
                                Are You Sure, You Want To Delete The Record With RRT-Rule-Num - {this.state.rrtRuleNum} ?
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="py-1 d-flex justify-content-center">
                        <div>
                            <Button
                                variant="warning"
                                onClick={() =>
                                    this.setState({
                                        showDeleteRecordConfirm: false,
                                    })
                                }
                            >
                                No
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="warning"
                                className="mx-2 px-3"
                                onClick={this.onHandleDeleteRefundDetails}
                            >
                                Yes
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };

    onHandleDeleteRefundDetails = () => {
        axios
            .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/refundrules/refund/${this.state.rrtRuleNum}`, {
                headers: {
                    ...getHeadersShared2().headers,
                    Authorization: this.state.accessToken,
                    "userId": this.state.ID,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let newRows = this.state.rows.filter((row) => {
                        return (row.rrtRuleNum) !== this.state.rrtRuleNum;
                    });

                    this.setState({
                        submitStatusRefundDetails: "OK",
                        submitMsgRefundDetails: response.data.responseString,
                        rows: newRows,
                    });
                } else {
                    this.setState({
                        submitStatusRefundDetails: "Error",
                        submitMsgRefundDetails: response.data.responseString,
                    });
                }
                this.onHandleClearRefundDetails();
            });
        this.setState({ showDeleteRecordConfirm: false });
    };

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="Refund Rule Maintenance"
                />
                <div className="container-fluid" data-test="component-network-list">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title main-heading ">
                                Refund Rule Maintenance
                            </h4>
                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    Refund screen
                                </div>
                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg-4">
                                                <label htmlFor="RRTIsoCode" className="font-weight-bold">RRT-ISO-Code</label>
                                                <input
                                                    type="text"
                                                    maxLength="3"
                                                    className="form-control form-control-sm"
                                                    name="rrtIsoCode"
                                                    value={this.state.rrtIsoCode}
                                                    onChange={this.onChangeControlRRTIsoDetails}
                                                    placeholder="RRT-ISO-Code"
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="RRTNaid" className="font-weight-bold">RRT-NAID</label>
                                                <input
                                                    type="text"
                                                    maxLength="5"
                                                    className="form-control form-control-sm"
                                                    name="rrtNaid"
                                                    value={this.state.rrtNaid}
                                                    onChange={this.onChangeControlRRTNaidDetails}
                                                    placeholder="RRT-NAID"
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="RRTAction" className="font-weight-bold">RRT-Action</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="rrtAction"
                                                    value={this.state.rrtAction}
                                                    onChange={
                                                        this.onChangeControlActionDetails
                                                    }>
                                                    <option value="">Select</option>
                                                    {this.getRRTActionsList()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-4">
                                                <label htmlFor="RRTTxnStatus" className="font-weight-bold">RRT-TXN-Status</label>
                                                <input
                                                    type="text"
                                                    maxLength="8"
                                                    className="form-control form-control-sm"
                                                    name="rrtTxnStatus"
                                                    value={this.state.rrtTxnStatus}
                                                    onChange={this.onChangeControlRRTTxnStatusDetails}
                                                    placeholder="RRT-TXN-Status"
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="RRTChannel" className="font-weight-bold">RRT-Channel</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="rrtChannel"
                                                    value={this.state.rrtChannel}
                                                    onChange={
                                                        this.onChangeControlChannelDetails
                                                    }>
                                                    <option value="">Select</option>
                                                    {this.getRRTChannelsList()}
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="RRT-BRAND" className="font-weight-bold">RRT-BRAND</label>
                                                <select className="form-control form-control-sm"
                                                    name="rrtBrand"
                                                    value={this.state.rrtBrand}
                                                    onChange={
                                                        this.onChangeControlBrandDetails
                                                    }>
                                                    <option value="">Select</option>
                                                    {this.getRRTBrandsList()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    onClick={this.onHandleBackRefundDetails}
                                                >
                                                    TMON MAINT
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    disabled={this.state.enableBtn || !this.state.accessGrantedWrite}
                                                    onClick={
                                                        this.onHandleAddRefundDetails
                                                    }
                                                >
                                                    ADD
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    disabled={!this.state.enableBtn || !this.state.accessGrantedWrite}
                                                    onClick={
                                                        this.onHandleUpdateRefundDetails
                                                    }
                                                >
                                                    UPDATE
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    onClick={
                                                        this.onHandleClearRefundDetails
                                                    }
                                                >
                                                    CLEAR
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning"
                                                    onClick={() => {
                                                        this.setState({
                                                            showDeleteRecordConfirm: true,
                                                        });
                                                    }}
                                                    disabled={!this.state.enableBtn || !this.state.accessGrantedWrite}
                                                >
                                                    DELETE
                                                </button>
                                                <div className="row mar-top10">
                                                    <div className="col-lg-2"></div>
                                                    <div id="mydiv" className="col-lg-8">
                                                        {this.state.submitStatusRefundDetails ===
                                                            "OK" && (
                                                                <div className="alert alert-success alert-padding notification">
                                                                    {this.state.submitMsgRefundDetails}
                                                                </div>
                                                            )}
                                                        {this.state.submitStatusRefundDetails ===
                                                            "Error" && (
                                                                <div className="alert alert-danger alert-padding notification">
                                                                    {this.state.submitMsgRefundDetails}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-lg-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {this.getQueueMantenanceGroupDetailsModal()}
                            <div className="card mar-top20">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    REFUND DETAILS DATA
                                </div>
                                <div className="card-body card-body-custom">
                                    <div className="pagination-dropdown">
                                        <label htmlFor="pagination-refund" className="font-weight-bold">
                                            No of records per page:
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={(e) => {
                                                this.onChangeControlRefundDetails(e);
                                                this.onPageSizeChangedRefundDetails(e);
                                            }}
                                            value={this.state.paginationValQueue}
                                            name="paginationDropdown"
                                        >
                                            {this.getPaginationDropdownsList()}
                                        </select>
                                    </div>
                                    <div
                                        className="ag-theme-balham"
                                        style={{ height: 250, width: "100%" }}
                                    >
                                        <AgGridReact
                                            rowData={this.state.rows}
                                            columnDefs={this.state.columns}
                                            defaultColDef={this.state.defaultColumnDef}
                                            pagination={true}
                                            paginationPageSize={25}
                                            onGridReady={this.state.accessGrantedRead && this.onGridReadyRefundDetails}
                                            enableCellTextSelection={true}
                                            overlayLoadingTemplate={
                                                this.state.overlayLoadingTemplate
                                            }
                                            overlayNoRowsTemplate={
                                                this.state.overlayNoRowsTemplate
                                            }
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withOktaAuth(RefundRuleMaintenance);