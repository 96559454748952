import { AgGridReact } from "ag-grid-react";
import React from "react";

export default function ReceiverDetails(props) {

    const payload = JSON.parse(props.data);

    var phnLst = payload?.orderitemlst[0].orderitem?.rcvr?.phnlst;
    const rowDataPhnLst = phnLst !== undefined && phnLst !== null ? phnLst : [];
    const colDefsPhnLst = [
        { field: 'typ', headerName: "Type", width: "80%" },
        { field: 'isdcd', headerName: "ISD-CD", width: "80%" },
        { field: 'stdcs', headerName: "STD-CS", width: "80%" },
        { field: 'num', headerName: "Number", width: "auto" },
        { field: 'phnstr', headerName: "Phone", width: "auto" },
    ];

    var phoneList = [];
    const phoneObject = payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.phone;
    if (phoneObject !== undefined && phoneObject !== null) {
        phoneList.push(phoneObject);
    }
    const rowDataPhoneList = phoneList;
    const colDefsPhoneList = [
        { field: 'typ', headerName: "Type", width: "80%" },
        { field: 'isdcd', headerName: "ISD-CD", width: "80%" },
        { field: 'stdcs', headerName: "STD-CS", width: "80%" },
        { field: 'num', headerName: "Number", width: "auto" },
        { field: 'phnstr', headerName: "Phone", width: "auto" },
    ];

    var drftAmtLst = payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.drftamtlst;
    const rowDataDraftList = drftAmtLst !== undefined && drftAmtLst !== null ? drftAmtLst : [];
    const colDefsDraftList = [
        { field: 'num', headerName: "Draft Number", width: "250%" },
        { field: 'amt', headerName: "Amount", width: "250%" },
        { field: 'ind', headerName: "Indicator", width: "250%" },
        { field: 'bcdchkbank', headerName: "Check Bank", width: "auto" },
        { field: 'bcdchkacct', headerName: "Check Account", width: "auto" },
    ];

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            RECEIVER DETAILS
                        </h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Receiver Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.name?.full}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">First Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.name?.fst}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Middle Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.name?.mdl}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Last Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.name?.lst}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Email: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.email}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Type: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.typ}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Id: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.id}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Product Type: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.prodtyp}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Galactic Id: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.glctid}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Name Type: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.typ}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Business Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.comp}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Home Address
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Type: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr1: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.addr1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr2: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.addr2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr3: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.addr3}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">City: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.city}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.st}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.zip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.addr?.cntry}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Phone List
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataPhnLst}
                                                                    columnDefs={colDefsPhnLst}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Loyalty Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Loyalty ID/PCP Number: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Level Code: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.lvlcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Segment Code: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.segcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Points Assigned: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.ptsassgn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total Points: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.totpts}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PCP Change Flag: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.lylty?.pcpchg}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Preference Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SMS Opt In / Out: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.pref?.smsopt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SMS Language: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.pref?.smslng}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Notification Indicator: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.pref?.ntfyind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Opt In / Out: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.pref?.emailopt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Compliance Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Compliance ID: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.cmpl?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.cmpl?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PEP Sender/Receiver Indicator: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.cmpl?.pepind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Enrol GDPR Regulation: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.cmpl?.gdprenrl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SP ID + SP Name: </span>{payload?.orderitemlst[0].orderitem?.rcvr?.cmpl?.spidnm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Agent Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Terminal ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.trmid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Operator ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.oprid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.name}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flanm?.full}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">First Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flanm?.fst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Middle Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flanm?.mdl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Last Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flanm?.lst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Site ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.site}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">N/W ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.netid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Demographic Area: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.dma}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Settlement Cycle: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.stlmtcyc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Settlement Product Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.stlmtprodcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">POS Version: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.posver}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Counter ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.cntrid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Supervisor ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flasupid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SIN Number: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.sinnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">MMA Flag: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.mma}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pseudo Account: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.psuedacct}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fraud Prevention: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.flafrdp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Template: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.tmpl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Commission Base: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.cmmbs}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent External Term ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.etrmid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Equipment: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.equip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Control Office: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.cntloffc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Internal Term ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.itrmid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reg Settl. Ind: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.rgstlmtind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Fund Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.payfundtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Class: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.cls}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent TaxId: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.taxid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent ADRT: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.adrt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent EOBT: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.eobt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent AAR Route: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.aarrte}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Weekday Open Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.wdopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Weekday Close Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.wdclos}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sat Open Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.saopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sat Close Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.saclos}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sun Open Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.suopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sun Close Hr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.suclos}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-6">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Agent Home Address
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.typ}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr1: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.addr1}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr2: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.addr2}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr3: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.addr3}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">City: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.city}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">State: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.st}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.zip}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Country: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.agt?.addr?.cntry}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Agent Phone List
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataPhoneList}
                                                                                    columnDefs={colDefsPhoneList}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Payment Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payment Token ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.tknid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Swift Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.swiftcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sort Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.sortcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Number: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.acctnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Bank Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.acctnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.acctcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Exp: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.acctexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.accttyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Auth: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.acctauth}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Branch Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.brnchcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.taxid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Receive Delay: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.rcvdelay}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Transaction ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.exttxnid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">System Trace: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.systrc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payment Emulated: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.pymteml}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Paypin: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.paypin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">6I AP_Card CUB: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.cclst4}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Global Debit Txn: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.glbldbt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Bank Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.bankcd}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Bill Payment Details
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.id}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment Source: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.source}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.name}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Reference Number: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.refnum}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin City: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.origid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin Comp: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.origcomp}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin Acc: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.origacct}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Collector Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.clctrrid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">MTReply: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.mtrply}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Market Vertical: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.mktvrt}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">VRU Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.vruid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Total Charge: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.totchgs}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.dlvtyp}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Prepaid Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.prpdind}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Max Device Amt: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.maxdevamt}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Ref Num: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.refnbr}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Attn Line: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.attnln}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Acc Num: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.acctnbr}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery Device-ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.ddevid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Device-ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.ndevid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery Device Class: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.ddevcls}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Device Class: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.ndevcls}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Seq: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.pymntdtl?.billpymt?.nseqnbr}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Draft List
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataDraftList}
                                                                                    columnDefs={colDefsDraftList}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}