import { AgGridReact } from "ag-grid-react";
import React from "react";

export default function SenderDetails(props) {

    const payload = JSON.parse(props.data);

    var phnLst = payload?.orderdet?.sndr?.phnlst
    const rowDataPhnLst = phnLst !== undefined && phnLst !== null ? phnLst : [];
    const colDefsPhnLst = [
        { field: 'typ', headerName: "Type", width: "250%" },
        { field: 'isdcd', headerName: "ISD-CD", width: "250%" },
        { field: 'stdcs', headerName: "STD-CS", width: "250%" },
        { field: 'num', headerName: "Number", width: "auto" },
        { field: 'phnstr', headerName: "Phone", width: "auto" },
    ];

    var phoneList = [];
    const phoneObject = payload?.orderdet?.txninf?.agt?.phone;
    if (phoneObject !== undefined && phoneObject !== null) {
        phoneList.push(phoneObject);
    }
    const rowDataPhoneList = phoneList;
    const colDefsPhoneList = [
        { field: 'typ', headerName: "Type", width: "80%" },
        { field: 'isdcd', headerName: "ISD-CD", width: "80%" },
        { field: 'stdcs', headerName: "STD-CS", width: "80%" },
        { field: 'num', headerName: "Number", width: "auto" },
        { field: 'phnstr', headerName: "Phone", width: "auto" },
    ];

    var rcptMsgLst = payload?.orderdet?.txninf?.agt?.rcptmsglst;
    const rowDataRcptmsglst = rcptMsgLst !== undefined && rcptMsgLst !== null ? rcptMsgLst : [];
    const colDefsRcptmsglst = [
        { headerName: "Message", width: "100%" },
    ];

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            SENDER DETAILS
                        </h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Sender Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderdet?.sndr?.name?.full}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">First Name: </span>{payload?.orderdet?.sndr?.name?.fst}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Middle Name: </span>{payload?.orderdet?.sndr?.name?.mdl}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Last Name: </span>{payload?.orderdet?.sndr?.name?.lst}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Email: </span>{payload?.orderdet?.sndr?.email}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Type: </span>{payload?.orderdet?.sndr?.typ}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Source: </span>{payload?.orderdet?.sndr?.source}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Product Type: </span>{payload?.orderdet?.sndr?.prodtyp}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Id: </span>{payload?.orderdet?.sndr?.id}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Galactic Id: </span>{payload?.orderdet?.sndr?.glctid}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Name Type: </span>{payload?.orderdet?.sndr?.name?.typ}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Business Name: </span>{payload?.orderdet?.sndr?.name?.typ}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Home Address
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Type: </span>{payload?.orderdet?.sndr?.addr?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr1: </span>{payload?.orderdet?.sndr?.addr?.addr1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr2: </span>{payload?.orderdet?.sndr?.addr?.addr2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr3: </span>{payload?.orderdet?.sndr?.addr?.addr3}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">City: </span>{payload?.orderdet?.sndr?.addr?.city}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State: </span>{payload?.orderdet?.sndr?.addr?.st}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderdet?.sndr?.addr?.zip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country: </span>{payload?.orderdet?.sndr?.addr?.cntry}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Billing Address
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Type: </span>{payload?.orderdet?.sndr?.billaddr?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr1: </span>{payload?.orderdet?.sndr?.billaddr?.addr1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr2: </span>{payload?.orderdet?.sndr?.billaddr?.addr2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Addr3: </span>{payload?.orderdet?.sndr?.billaddr?.addr3}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">City: </span>{payload?.orderdet?.sndr?.billaddr?.city}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State: </span>{payload?.orderdet?.sndr?.billaddr?.st}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderdet?.sndr?.billaddr?.zip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country: </span>{payload?.orderdet?.sndr?.billaddr?.cntry}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Phone List
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataPhnLst}
                                                                    columnDefs={colDefsPhnLst}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Loyalty Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Loyalty ID/PCP Number: </span>{payload?.orderdet?.sndr?.lylty?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderdet?.sndr?.lylty?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Level Code: </span>{payload?.orderdet?.sndr?.lylty?.lvlcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Segment Code: </span>{payload?.orderdet?.sndr?.lylty?.segcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Points Assigned: </span>{payload?.orderdet?.sndr?.lylty?.ptsassgn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total Points: </span>{payload?.orderdet?.sndr?.lylty?.totpts}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PCP Change Flag: </span>{payload?.orderdet?.sndr?.lylty?.pcpchg}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Preference Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SMS Opt In / Out: </span>{payload?.orderdet?.sndr?.pref?.smsopt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SMS Language: </span>{payload?.orderdet?.sndr?.pref?.smslng}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Notification Indicator: </span>{payload?.orderdet?.sndr?.pref?.ntfyind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Opt In / Out: </span>{payload?.orderdet?.sndr?.pref?.emailopt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Compliance Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Compliance ID: </span>{payload?.orderdet?.sndr?.cmpl?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderdet?.sndr?.cmpl?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PEP Sender/Receiver Indicator: </span>{payload?.orderdet?.sndr?.cmpl?.pepind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Enrol GDPR Regulation: </span>{payload?.orderdet?.sndr?.cmpl?.gdprenrl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SP ID + SP Name: </span>{payload?.orderdet?.sndr?.cmpl?.spidnm}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Agent Details
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent ID: </span>{payload?.orderdet?.txninf?.agt?.id}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderdet?.txninf?.agt?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Terminal ID: </span>{payload?.orderdet?.txninf?.agt?.tmrid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Operator ID: </span>{payload?.orderdet?.txninf?.agt?.oprid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Name: </span>{payload?.orderdet?.txninf?.agt?.name}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderdet?.txninf?.agt?.flanm?.full}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">First Name: </span>{payload?.orderdet?.txninf?.agt?.flanm?.fst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Middle Name: </span>{payload?.orderdet?.txninf?.agt?.flanm?.mdl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Last Name: </span>{payload?.orderdet?.txninf?.agt?.flanm?.lst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Site ID: </span>{payload?.orderdet?.txninf?.agt?.site}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent N/W ID: </span>{payload?.orderdet?.txninf?.agt?.netid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Demographic Area: </span>{payload?.orderdet?.txninf?.agt?.dma}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Settlement Cycle: </span>{payload?.orderdet?.txninf?.agt?.stlmtcyc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Settlement Product Code: </span>{payload?.orderdet?.txninf?.agt?.stlmtprodcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Class: </span>{payload?.orderdet?.txninf?.agt?.cls}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">POS Version: </span>{payload?.orderdet?.txninf?.agt?.posver}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Counter ID: </span>{payload?.orderdet?.txninf?.agt?.cntrid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SIN Number: </span>{payload?.orderdet?.txninf?.agt?.sinnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SIN Number: </span>{payload?.orderdet?.txninf?.agt?.sinnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent MMA Flag: </span>{payload?.orderdet?.txninf?.agt?.mma}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pseudo Account: </span>{payload?.orderdet?.txninf?.agt?.psuedacct}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Supervisor ID: </span>{payload?.orderdet?.txninf?.agt?.flasupid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fraud Prevention: </span>{payload?.orderdet?.txninf?.agt?.flafrdp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Template: </span>{payload?.orderdet?.txninf?.agt?.tmpl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Commission base: </span>{payload?.orderdet?.txninf?.agt?.cmmbs}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent External Term Id: </span>{payload?.orderdet?.txninf?.agt?.etrmid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Equipment: </span>{payload?.orderdet?.txninf?.agt?.equip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Control Office: </span>{payload?.orderdet?.txninf?.agt?.cntloffc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Internal Term ID: </span>{payload?.orderdet?.txninf?.agt?.itrmid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Tax ID: </span>{payload?.orderdet?.txninf?.agt?.taxid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent AAR Route: </span>{payload?.orderdet?.txninf?.agt?.aarrte}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reg Settl. Ind: </span>{payload?.orderdet?.txninf?.agt?.rgstlmtind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Pay Funds: </span>{payload?.orderdet?.txninf?.agt?.payfundtyp}
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent EOBT: </span>{payload?.orderdet?.txninf?.agt?.eobt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Weekday Open Hr: </span>{payload?.orderdet?.txninf?.agt?.wdopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Weekday Close Hr: </span>{payload?.orderdet?.txninf?.agt?.wdclos}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sat Open Hr: </span>{payload?.orderdet?.txninf?.agt?.saopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sat Close Hr: </span>{payload?.orderdet?.txninf?.agt?.saclos}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sun Open Hr: </span>{payload?.orderdet?.txninf?.agt?.suopen}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sun Close Hr: </span>{payload?.orderdet?.txninf?.agt?.suclos}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent ADRT: </span>{payload?.orderdet?.txninf?.agt?.adrt}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Agent Home Address
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Type: </span>{payload?.orderdet?.txninf?.agt?.addr?.typ}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr1: </span>{payload?.orderdet?.txninf?.agt?.addr?.addr1}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr2: </span>{payload?.orderdet?.txninf?.agt?.addr?.addr2}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Addr3: </span>{payload?.orderdet?.txninf?.agt?.addr?.addr3}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">City: </span>{payload?.orderdet?.txninf?.agt?.addr?.city}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">State: </span>{payload?.orderdet?.txninf?.agt?.addr?.st}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderdet?.txninf?.agt?.addr?.zip}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Country: </span>{payload?.orderdet?.txninf?.agt?.addr?.cntry}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-6">
                                                                        <div className="card">
                                                                            <div className="card-header bg-dark text-white card-header-custom">
                                                                                Messages List
                                                                            </div>
                                                                            <div className="card-body card-body-custom">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="ag-theme-balham ml-3 mr-3"
                                                                                        style={{ height: 100, width: "100%" }}
                                                                                    >
                                                                                        <AgGridReact
                                                                                            rowData={rowDataRcptmsglst}
                                                                                            columnDefs={colDefsRcptmsglst}
                                                                                            enableCellTextSelection={true}
                                                                                        ></AgGridReact>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="card">
                                                                            <div className="card-header bg-dark text-white card-header-custom">
                                                                                Agent Phone List
                                                                            </div>
                                                                            <div className="card-body card-body-custom">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="ag-theme-balham ml-3 mr-3"
                                                                                        style={{ height: 100, width: "100%" }}
                                                                                    >
                                                                                        <AgGridReact
                                                                                            rowData={rowDataPhoneList}
                                                                                            columnDefs={colDefsPhoneList}
                                                                                            enableCellTextSelection={true}
                                                                                        ></AgGridReact>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        PAYMENT DETAILS
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payment Token ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.tknid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Source: </span>{payload?.orderdet?.txninf?.pymtdtl?.source}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Type: </span>{payload?.orderdet?.txninf?.pymtdtl?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Number: </span>{payload?.orderdet?.txninf?.pymtdtl?.acctnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Name: </span>{payload?.orderdet?.txninf?.pymtdtl?.acctnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Code: </span>{payload?.orderdet?.txninf?.pymtdtl?.acctcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Exp: </span>{payload?.orderdet?.txninf?.pymtdtl?.acctexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Type: </span>{payload?.orderdet?.txninf?.pymtdtl?.accttyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Account Auth: </span>{payload?.orderdet?.txninf?.pymtdtl?.acctauth}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Branch Code: </span>{payload?.orderdet?.txninf?.pymtdtl?.brnchcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.taxid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Receive Delay: </span>{payload?.orderdet?.txninf?.pymtdtl?.rcvdelay}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Transaction ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.exttxnid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">System Trace: </span>{payload?.orderdet?.txninf?.pymtdtl?.systrc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payment Emulated: </span>{payload?.orderdet?.txninf?.pymtdtl?.pymteml}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Paypin: </span>{payload?.orderdet?.txninf?.pymtdtl?.paypin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">6I AP_Card CUB: </span>{payload?.orderdet?.txninf?.pymtdtl?.cclst4}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Global Debit Txn: </span>{payload?.orderdet?.txninf?.pymtdtl?.glbldbt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Swift Code: </span>{payload?.orderdet?.txninf?.pymtdtl?.swiftcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sort Code: </span>{payload?.orderdet?.txninf?.pymtdtl?.sortcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Bank Code: </span>{payload?.orderdet?.txninf?.pymtdtl?.bankcd}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        Bill Payment Details
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.id}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment Source: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.source}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Bill Payment Name: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.name}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Reference Number: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.refnum}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin City: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.origid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin Comp: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.origcomp}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">QCVS Origin Acc: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.origacct}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Collector Id: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.clctrrid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">MTReply: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.mtrply}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Market Vertical: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.mktvrt}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">VRU Id: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.vruid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Total Charge: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.totchgs}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery Type: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.dlvtyp}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Prepaid Indicator: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.prpdind}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Max Device Amt: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.maxdevamt}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Ref Num: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.refnbr}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Attn Line: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.attnln}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">TX Comm Acc Num: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.acctnbr}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery Device-ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.ddevid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Device-ID: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.ndevid}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Delivery Device Class: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.ddevcls}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Device Class: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.ndevcls}
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <span className="font-weight-bold">Notification Seq: </span>{payload?.orderdet?.txninf?.pymtdtl?.billpymt?.nseqnbr}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Bill Name Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Full Name: </span>{payload?.orderdet?.txninf?.billnm?.full}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">First Name: </span>{payload?.orderdet?.txninf?.billnm?.fst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Middle Name: </span>{payload?.orderdet?.txninf?.billnm?.mdl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Last Name: </span>{payload?.orderdet?.txninf?.billnm?.lst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Name Type: </span>{payload?.orderdet?.txninf?.billnm?.typ}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Business Name: </span>{payload?.orderdet?.txninf?.billnm?.comp}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}