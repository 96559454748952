import { AgGridReact } from "ag-grid-react";
import React from "react";

export default function ReceiverTxnInfo(props) {

    const payload = JSON.parse(props.data);

    const promo = payload?.orderitemlst?.[0].orderitem?.txninf?.promo;
    const rowDataPromo = promo !== undefined && promo !== null ? promo : [];
    const colDefsPromo = [
        { field: 'cd', headerName: "Code", width: "125%" },
        { field: 'seq', headerName: "Sequence", width: "125%" },
        { field: 'name', headerName: "Name", width: "125%" },
        { field: 'disc', headerName: "Disc", width: "125%" },
        { field: 'desc', headerName: "Description", width: "125%" },
        { field: 'pin', headerName: "Pin", width: "125%" },
        { field: 'pintxt', headerName: "Pin Text", width: "125%" },
        { field: 'mst', headerName: "Message", width: "125%" },
    ];

    const prcssFlgLst = payload?.orderitemlst?.[0].orderitem?.txninf?.prcssflglst;
    const rowDataPrcssFlgLst = prcssFlgLst !== undefined && prcssFlgLst !== null ? prcssFlgLst : [];

    const addnInfo = payload?.orderitemlst?.[0].orderitem?.txninf?.addninf;
    const rowDataAddnInfo = addnInfo !== undefined && addnInfo !== null ? addnInfo : [];

    const webTmonAddnInfo = payload?.orderitemlst?.[0].orderitem?.txninf?.webtmonaddninf;
    const rowDataWebTmonAddnInfo = webTmonAddnInfo !== undefined && webTmonAddnInfo !== null ? webTmonAddnInfo : [];

    var colDefsFlagLists = [
        { field: 'typ', headerName: "Type", width: "125%" },
        { field: 'val', headerName: "Value", width: "125%" }
    ];

    const rowDataSamtlst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.samtlst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.samtlst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.samtlst : [];
    const rowDataSchglst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.schglst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.schglst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.schglst : [];
    const rowDataStaxlst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.staxlst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.staxlst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.staxlst : [];
    const rowDataRchglst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rchglst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rchglst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rchglst : [];
    const rowDataRtaxlst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rtaxlst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rtaxlst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.rtaxlst : [];
    const rowDataRefamtlst = payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.refamtlst !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.refamtlst !== null ? payload?.orderitemlst?.[0].orderitem?.txninf?.finc?.refamtlst : [];
    const colDefs = [
        { field: 'typ', headerName: "Type", width: "80%" },
        { field: 'lbl', headerName: "Label", width: "80%" },
        { field: 'amt', headerName: "Amount", width: "80%" },
    ];

    const recordingDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.recdttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const localRecordingDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.reclcldttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.reclcldttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.reclcldttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const payDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.paydttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    const localPayDateTime = payload?.orderitemlst?.[0].orderitem?.txninf?.paylcldttm !== undefined && payload?.orderitemlst?.[0].orderitem?.txninf?.paylcldttm !== null
        ? new Date(payload?.orderitemlst?.[0].orderitem?.txninf?.paylcldttm).toLocaleString('en-US', { timeZone: 'America/New_York' }) : '';

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            RECEIVER TRANSACTION INFO
                        </h4>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Transaction Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Funds Out: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.fundsout}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Overall Transaction Status: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.ostat}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Recording Date Time: </span>{recordingDateTime}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Local Recording Date Time: </span>{localRecordingDateTime}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Pay Date Time: </span>{payDateTime}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Local Pay Date Time: </span>{localPayDateTime}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Receiver Fund Status: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.rcvfundstat}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Queue Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.quenm}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Delivery Service Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dlvsvccd}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Delivery Service Route: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dlvsvcrt}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Delivery Service Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dlvsvcnm}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Delivery Route ESL: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dlvrtesl}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Delivery Method: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dlvmthd}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Principal Fix Indicator: </span> {payload?.orderitemlst?.[0].orderitem?.txninf?.prinfixind}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Blaze Response: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.riskresp}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Value Date: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.rcvfunddt}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">CSC Billing ID: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.csctxntyp}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Rec Directed Cnt: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.rdcnt}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Pricing Ovr: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.prcovr}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">D2B Disp Rsn: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.d2bdisp}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Dir Pay N/w Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.dirpaynetid}
                                            </div>
                                            <div className="col-lg-3">
                                                <span className="font-weight-bold">Ownership Flag: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.onwflg}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Promo
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataPromo}
                                                                    columnDefs={colDefsPromo}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Process Flag List
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataPrcssFlgLst}
                                                                    columnDefs={colDefsFlagLists}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Additional Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataAddnInfo}
                                                                    columnDefs={colDefsFlagLists}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Web Tmon Additional Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataWebTmonAddnInfo}
                                                                    columnDefs={colDefsFlagLists}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Financial Amount
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        samtlst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataSamtlst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        schglst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataSchglst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        staxlst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataStaxlst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        rchglst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataRchglst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        rtaxlst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataRtaxlst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        refamtlst
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div
                                                                                className="ag-theme-balham ml-3 mr-3"
                                                                                style={{ height: 150, width: "100%" }}
                                                                            >
                                                                                <AgGridReact
                                                                                    rowData={rowDataRefamtlst}
                                                                                    columnDefs={colDefs}
                                                                                    enableCellTextSelection={true}
                                                                                ></AgGridReact>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Transaction Process
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Test Question: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.tstq}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">APN Flag: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.apnid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Unpays Count: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.unpays}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Transaction Identifier: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.hybind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Txn Send Delay Hours: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.snddelayhr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Regulatory Call Back: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.regcallbck}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Delay Expiry Time: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.ddsexpdttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Receiver Redirection: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.rdactcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Redirection Sndr Match Score: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.rdsndrscr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Redirection Recv Match Score: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.rdrcvrscr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Pay N/W Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.payntwknm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">APN Reason Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.apnreascd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Old Payee Name: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.oldpayeenm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Partner Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.emailptnrid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Recpt Date: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.rcptdttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">ASIMOV Processor Fee: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.asimovfee}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS Misc Sub Type: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.wssubtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Blaze Extension Response: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.blzextresp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DRT Decision: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.drtdeci}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Reminder Number: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.remindernum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Total Reminders: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.totreminder}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email payment Acc Num: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.pymntacctnun}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Merchant Id: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.merchantid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">NA Comm Rsn Code: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.reascd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">NA Comm Rsn Desc: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.reasdesc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Next day Hrs: </span>{payload?.orderitemlst?.[0].orderitem?.txninf?.txnprcss?.nxtdayhrs}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}