import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact as AgGridReactARAD } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import ReactExport from "react-data-export";
import Header from "../../header/header";
import { getHeadersNRTAgtNW } from "../../utility/utility";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ConfigManagementQueryExecutor extends Component {

    state = {
        isLoading: false,
        isAuthorized: false,
        isWriteAllowed: false,
        userId: "",
        postgresCluster: "Transactional",
        fetchLimit: "100",
        query: "",
        actionResultIndicator: "",
        actionResultMessage: "",
        isQueryCompleted: false,
        isUpdateQueryError: false,
        updateQueryErrorMsg: "",
        isUpdateQuerySuccess: false,
        updateQuerySuccessMsg: "",
        isSelectQuerySuccess: false,
        selectQuerySuccessMsg: "",
        isSelectQueryError: false,
        selectQueryErrorMsg: "",
        rows: [],
        gridApi: null,
        paginationValQueue: "25",
        defaultColumnDef: {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
        },
        paginationOption: [25, 50, 100, 500, 1000, 2000],
        overlayLoadingTemplateARAD: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>',
        selectQueryExecutionResultcolumns: []
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;
        try {
            const id = jwt_decode(accessToken.value).hqintlId;
            const accessRole = jwt_decode(accessToken.value).appRoles;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)
                || accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id,
                    isWriteAllowed: false
                });
            }

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)
                || accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN)) {
                this.setState({
                    isAuthorized: true,
                    userId: id,
                    isWriteAllowed: true
                });
            }
        } catch (error) {
            // invalid token format
        }
    }

    onChangeControlUserSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    getPostgresClusterList = () => {
        let postgresCluster = ["Transactional", "Master", "Agent"];
        return postgresCluster.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getFetchLimitList = () => {
        let fetchLimit = ["500", "1000", "2000", "5000"];
        return fetchLimit.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    onHandleClear = (e) => {
        this.setState({
            rows: [],
            selectQueryExecutionResultcolumns: [],
            isLoading: false,
            postgresCluster: "Transactional",
            fetchLimit: "100",
            query: "",
            actionResultIndicator: "",
            actionResultMessage: "",
            isQueryCompleted: false,
            isUpdateQueryError: false,
            updateQueryErrorMsg: "",
            isUpdateQuerySuccess: false,
            updateQuerySuccessMsg: "",
            isSelectQuerySuccess: false,
            selectQuerySuccessMsg: "",
            isSelectQueryError: false,
            selectQueryErrorMsg: ""
        });
    }

    onPageSizeChangedConfigManagementDetails = (e) => {
        this.setState({ paginationValQueue: e.target.value });
        this.state.gridApi.paginationSetPageSize(Number(e.target.value));
    };

    onGridReadyConfigManagementDetails = (params) => {
        if (params) {
            this.setState({ gridApi: params.api });
        }
        if (this.state.rows.length === 0) {
            this.setState({
                rows: [],
                overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>'
            });
        }
    };

    onHandleSubmitQuery = (e) => {

        if (!this.onValidateConfigManagementValidation()) {
            return;
        }

        this.setState({
            rows: [],
            selectQueryExecutionResultcolumns: [],
            actionResultIndicator: "",
            actionResultMessage: "",
            isQueryCompleted: false,
            isUpdateQueryError: false,
            updateQueryErrorMsg: "",
            isUpdateQuerySuccess: false,
            updateQuerySuccessMsg: "",
            isSelectQuerySuccess: false,
            selectQuerySuccessMsg: "",
            isSelectQueryError: false,
            selectQueryErrorMsg: ""
        });

        let queryRequest = {};
        queryRequest.clusterName = this.state.postgresCluster;
        queryRequest.query = this.state.query;
        queryRequest.limit = this.state.fetchLimit === "" ? "100" : this.state.fetchLimit;

        if (queryRequest.query.toLowerCase().startsWith("select")) {
            this.onHandleSubmitSelectQueryRequest(queryRequest)
        } else if (queryRequest.query.toLowerCase().startsWith("insert")
            || queryRequest.query.toLowerCase().startsWith("update")
            || queryRequest.query.toLowerCase().startsWith("delete")) {
            if (this.state.isWriteAllowed) {
                this.onHandleSubmitUpdateQueryRequest(queryRequest)
            } else {
                this.setState({
                    actionResultIndicator: "Error",
                    actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action",
                });
            }
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "This Functionality Not Supported !",
            });
        }
    }

    onHandleSubmitSelectQueryRequest = (queryRequest) => {
        this.setState({
            isLoading: true
        });
        queryRequest.query = window.btoa(queryRequest.query);
        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/query/retrieve`,
            queryRequest,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            this.setState({
                isQueryCompleted: true
            });
            if (response.status === 200) {
                if (response.data.apiResponse.result === "Query Executed Successfully !") {
                    this.setState({
                        isSelectQuerySuccess: true,
                        selectQuerySuccessMsg: response.data.apiResponse.result,
                        isLoading: false,
                        selectQueryExecutionResultcolumns: response.data.dataResponse.tableSchemaList,
                        rows: response.data.dataResponse.resultList,
                    });
                } else {
                    this.setState({
                        isSelectQueryError: true,
                        selectQueryErrorMsg: response.data.apiResponse.result,
                        isLoading: false
                    });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    onHandleSubmitUpdateQueryRequest = (queryRequest) => {
        this.setState({
            isLoading: true
        });
        queryRequest.query = window.btoa(queryRequest.query);
        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/query/update`,
            queryRequest,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            this.setState({
                isQueryCompleted: true
            });
            if (response.status === 200) {
                if (response.data.result.startsWith("Query Executed Successfully !")) {
                    this.setState({
                        isUpdateQuerySuccess: true,
                        updateQuerySuccessMsg: response.data.result,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isUpdateQueryError: true,
                        updateQueryErrorMsg: response.data.result,
                        isLoading: false
                    });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    onValidateConfigManagementValidation = (e) => {
        if (this.state.query === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Select Mandatory Fields !",
            });
            return false;
        }
        return true;
    }

    onHandleUpdateConfigAccessManagement = (e) => {
        this.props.history.push("/edit-config-management-access");
    }

    onHandleUpdateRedisManagement = (e) => {
        this.props.history.push("/config-management-redis-update");
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="CONFIG QUERY MANAGEMENT"
                />

                <div className="container-fluid" data-test="">
                    {this.state.isAuthorized && (
                        <div>
                            <h4 className="card-title main-heading">
                                CONFIG QUERY MANAGEMENT
                            </h4>

                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    EXECUTE QUERY
                                </div>

                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label className="font-weight-bold">Postgres Cluster</label>
                                                <select className="form-control form-control-sm"
                                                    name="postgresCluster"
                                                    value={this.state.postgresCluster}
                                                    data-test="select-postgresCluster"
                                                    onChange={
                                                        this.onChangeControlUserSelectionDetails
                                                    }>
                                                    {this.getPostgresClusterList()}
                                                </select>
                                            </div>
                                            <div className="col-lg">
                                                <label className="font-weight-bold">Fetch Limit</label>
                                                <select className="form-control form-control-sm"
                                                    name="fetchLimit"
                                                    value={this.state.fetchLimit}
                                                    data-test="select-fetchLimit"
                                                    onChange={
                                                        this.onChangeControlUserSelectionDetails
                                                    }>
                                                    <option value="">Select (Default 100)</option>
                                                    {this.getFetchLimitList()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label htmlFor="query" className="font-weight-bold">*Query</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    name="query"
                                                    value={this.state.query}
                                                    data-test="input-query"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Query" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label className="">Note : Result Size Limit Is Restricted To 2000 Rows Only !</label>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">
                                                {this.state.isLoading ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10" disabled>
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp;LOADING...
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10"
                                                            data-test="button-submit"
                                                            onClick={this.onHandleSubmitQuery}>
                                                            SUBMIT
                                                        </button>
                                                    )}
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-clear"
                                                    onClick={this.onHandleClear}>
                                                    CLEAR
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-redis-mgmt"
                                                    onClick={this.onHandleUpdateRedisManagement}>
                                                    REDIS CACHE MANAGEMENT
                                                </button>
                                                {this.state.isWriteAllowed &&
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10"
                                                            data-test="button-update"
                                                            onClick={this.onHandleUpdateConfigAccessManagement}>
                                                            UPDATE ACCESS
                                                        </button>
                                                    )
                                                }
                                            </div>
                                            <div className="col-lg-3">
                                                {this.state.actionResultIndicator ===
                                                    "Error" && (
                                                        <div className="alert alert-danger alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                                {this.state.actionResultIndicator ===
                                                    "OK" && (
                                                        <div className="alert alert-success alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {this.state.isQueryCompleted && (
                                <div className="card mar-top10">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        RESULT
                                    </div>
                                    <div className="card-body card-body-custom">

                                        {this.state.isUpdateQueryError && (
                                            <div className="alert alert-danger margin-0">
                                                {this.state.updateQueryErrorMsg}
                                            </div>
                                        )}
                                        {this.state.isUpdateQuerySuccess && (
                                            <div className="alert alert-success margin-0">
                                                {this.state.updateQuerySuccessMsg}
                                            </div>
                                        )}
                                        {this.state.isSelectQueryError && (
                                            <div className="alert alert-danger margin-0">
                                                {this.state.selectQueryErrorMsg}
                                            </div>
                                        )}
                                        {this.state.isSelectQuerySuccess && (
                                            <span>
                                                <ExcelFile filename={"Report"}
                                                    element={
                                                        <button className="btn btn-warning mar-right10" type="button" data-test="button-submit" style={{ float: "right" }}>
                                                            Download Data
                                                        </button>
                                                    }>
                                                    <ExcelSheet data={this.state.rows} name="Report">
                                                        {this.state.selectQueryExecutionResultcolumns.map((item, index) => {
                                                            return <ExcelColumn label={item.headerName} value={item.field} key={index} />
                                                        })}
                                                    </ExcelSheet>
                                                </ExcelFile>
                                                <div className="pagination-dropdown">
                                                    <label htmlFor="pagination-select-label" className="font-weight-bold">
                                                        No of records per page:
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={(e) => {
                                                            this.onChangeControlUserSelectionDetails(e);
                                                            this.onPageSizeChangedConfigManagementDetails(e);
                                                        }}
                                                        value={this.state.paginationValQueue}
                                                        name="paginationDropdownQueue"
                                                        data-test="select-dropdown"
                                                    >
                                                        {this.state.paginationOption.map(
                                                            (val) => {
                                                                return (
                                                                    <option key={val} value={val}>
                                                                        {val}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </div>
                                                <div
                                                    className="ag-theme-balham"
                                                    style={{ height: 380, width: "100%" }}
                                                >
                                                    <AgGridReactARAD
                                                        rowData={this.state.rows}
                                                        columnDefs={this.state.selectQueryExecutionResultcolumns}
                                                        defaultColDef={this.state.defaultColumnDef}
                                                        pagination={true}
                                                        paginationPageSize={25}
                                                        onGridReady={this.onGridReadyConfigManagementDetails}
                                                        data-test="component-grid"
                                                        enableCellTextSelection={true}
                                                        overlayLoadingTemplate={
                                                            this.state.overlayLoadingTemplateARAD
                                                        }
                                                        overlayNoRowsTemplate={
                                                            this.state.overlayNoRowsTemplateARAD
                                                        }
                                                    ></AgGridReactARAD>
                                                </div>
                                            </span>
                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
export default withOktaAuth(ConfigManagementQueryExecutor);