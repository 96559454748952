import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import Header from "../../header/header";

class cbviewSearchMtcnMocn extends Component {

    state = {
        isAuthorized: false,
        mtcn: "",
        mocn: "",
        error: "",
        errorMessage: ""
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;

        try {
            const accessRole = jwt_decode(accessToken.value).appRoles;
            const id = jwt_decode(accessToken.value).hqintlId;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_MTUTILITY) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id,
                });
            }
        } catch (error) {
            // invalid token format
        }
    }


    onChangeControlUserSelectionMTCN = (e) => {
        var value = e.target.value;
        value = value.replace(/[-\s_]/g, "").replace(/[^\d]/, "");
        if (value.length > 16) {
            value = value.substring(0, 16);
        }
        this.setState({ [e.target.name]: value });
        this.setState({ 'mocn': '' });
    };

    onChangeControlUserSelectionMOCN = (e) => {
        var value = e.target.value;
        value = value.replace(/[-\s_]/g, "").replace(/[^\d]/, "");
        if (value.length > 10) {
            value = value.substring(0, 10);
        }
        this.setState({ [e.target.name]: value });
        this.setState({ 'mtcn': '' });
    };


    onHandleSearchDataByMtcnOrMocnJson = () => {

        this.setState({
            error: false,
            errorMessage: "",
        });

        if (this.state.mtcn !== '') {
            if (this.state.mtcn.length !== 16) {
                this.setState({
                    error: true,
                    errorMessage: "Please enter 16 digit value for mtcn",
                });
            }

            // Execution For MTCN Search
            this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-json", state: { mtcn: this.state.mtcn } });

        } else if (this.state.mocn !== '') {
            if (this.state.mocn.length !== 10) {
                this.setState({
                    error: true,
                    errorMessage: "Please enter 10 digit value for mocn",
                });
            }

            // Execution For MOCN Search
            this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-json", state: { mocn: this.state.mocn } });

        } else {
            this.setState({
                error: true,
                errorMessage: "Please enter value in one of the fields",
            });
        }
    };

    onHandleSearchDataByMtcnOrMocnUI = () => {

        this.setState({
            error: false,
            errorMessage: "",
        });

        if (this.state.mtcn !== '') {
            if (this.state.mtcn.length !== 16) {
                this.setState({
                    error: true,
                    errorMessage: "Please enter 16 digit value for mtcn",
                });
            }

            // Execution For MTCN Search
            this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-ui", state: { mtcn: this.state.mtcn } });

        } else if (this.state.mocn !== '') {
            if (this.state.mocn.length !== 10) {
                this.setState({
                    error: true,
                    errorMessage: "Please enter 10 digit value for mocn",
                });
            }

            // Execution For MOCN Search
            this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-ui", state: { mocn: this.state.mocn } });

        } else {
            this.setState({
                error: true,
                errorMessage: "Please enter value in one of the fields",
            });
        }
    };

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="CBVIEW"
                />

                <div className="container-fluid">
                    <div className="card-body">
                        {this.state.isAuthorized && (
                            <div>
                                <h4 className="card-title main-heading">
                                    CBVIEW
                                </h4>
                                <div className="row">
                                    <div className="offset-3 col-lg-6 col-sm-6 col-xs-6">
                                        <div className="card">
                                            <div className="card-header bg-dark text-white card-header-custom">
                                                TRANSACTION SEARCH
                                            </div>
                                            <div className="card-body card-body-custom">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" htmlFor="mtcn"> MTCN </label>
                                                                <input type="text" className="form-control form-control-sm"
                                                                    maxLength="20"
                                                                    name="mtcn"
                                                                    data-test="input-mtcn"
                                                                    placeholder="MTCN"
                                                                    onChange={this.onChangeControlUserSelectionMTCN}
                                                                    value={this.state.mtcn}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label className="font-weight-bold" htmlFor="mocn"> CONTROL NUMBER (MOCN) </label>
                                                                <input type="text" className="form-control form-control-sm"
                                                                    maxLength="20"
                                                                    name="mocn"
                                                                    data-test="input-mocn"
                                                                    placeholder="MOCN"
                                                                    onChange={this.onChangeControlUserSelectionMOCN}
                                                                    value={this.state.mocn}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3" />
                                                        <div className="col-lg-6 text-center">
                                                            {this.state.error && (
                                                                <div className="alert alert-danger alert-padding notification">
                                                                    {this.state.errorMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-4" />
                                                        <div className="col-lg-6">
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning mar-right10"
                                                                data-test="button-search"
                                                                onClick={this.onHandleSearchDataByMtcnOrMocnJson}>
                                                                JSON VIEW
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning"
                                                                data-test="button-search"
                                                                onClick={this.onHandleSearchDataByMtcnOrMocnUI}>
                                                                UI VIEW
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!this.state.isAuthorized && (
                            <div className="row">
                                <div className="col-lg-4 mar-top10"></div>
                                <div className="col-lg-4 mar-top10">
                                    <div className="container-fluid alert alert-danger alert-padding">
                                        Permission Denied ! You Are Not Authorized To Perform This Action.
                                    </div>
                                </div>
                            </div>
                        )}
                    </div >
                </div >
            </>
        );
    }
}

export default withOktaAuth(cbviewSearchMtcnMocn);
