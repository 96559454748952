import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact as AgGridReactARAD } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { IconContext as IconContextARAD } from "react-icons";
import { AiTwotoneDelete } from "react-icons/ai";
import Header from "../../header/header";
import { getHeadersNRTAgtNW } from "../../utility/utility";

class ConfigManagementRedisUpdate extends Component {

    state = {
        isLoading: false,
        isAuthorized: false,
        isReadAllowed: false,
        isWriteAllowed: false,
        userId: "",
        redisCacheKey: "",
        redisCacheKeyPattern: "",
        region: "US_EAST_1",
        limit: "100",
        showDeleteRedisConfigMgmtConfirmModal: false,
        actionResultIndicator: "",
        actionResultMessage: "",
        rows: null,
        enableBtn: false,
        gridApi: null,
        paginationValQueue: "25",
        defaultColumnDef: {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
        },
        paginationOption: [25, 50, 100, 500],
        overlayLoadingTemplateARAD: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>',
        configRedisGridcolumns: [
            {
                headerName: "ACTION",
                field: "value",
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <span
                                className="cursor-pointer text-center" title="Delete"
                                onClick={() => this.onHandleDeleteRedisConfigMgmtConfirm(params)}
                            >
                                <IconContextARAD.Provider value={{ color: "#FDD017", size: "20px" }}>
                                    <AiTwotoneDelete />
                                </IconContextARAD.Provider>
                            </span>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                floatingFilter: false,
                width: "100px"
            },
            { field: "redisCacheKey", headerName: "Redis Cache Key", width: "300px" },
            { field: "redisCacheValue", headerName: "Redis Cache Value", width: "650px", wrapText: true, autoHeight: true },
            { field: "redisCacheTtl", headerName: "Redis Cache TTL", width: "150px" },
            { field: "redisCacheRegion", headerName: "Redis Cache Region", width: "150px" },
        ]
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;
        try {
            const id = jwt_decode(accessToken.value).hqintlId;
            const accessRole = jwt_decode(accessToken.value).appRoles;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)
                || accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id,
                    isReadAllowed: true,
                    isWriteAllowed: false
                });
            }

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)
                || accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN)) {
                this.setState({
                    isAuthorized: true,
                    userId: id,
                    isReadAllowed: true,
                    isWriteAllowed: true
                });
            }
        } catch (error) {
            // invalid token format
        }
    }

    onHandleClearRedisConfigMgmt = (e) => {
        this.setState({
            isLoading: false,
            redisCacheKeyPattern: "",
            limit: "10",
            region: "US_EAST_1",
            actionResultIndicator: "",
            actionResultMessage: "",
        });
    }

    onChangeControlUserSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    getFetchLimitList = () => {
        let fetchLimit = ["500", "1000", "2000", "3000"];
        return fetchLimit.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getFetchRegionList = () => {
        let fetchRegion = ["US_EAST_2", "BOTH"];
        return fetchRegion.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    onHandleBackToConfigQueryManagement = (e) => {
        this.props.history.push("/config-management-query-executor");
    }

    onHandleSearchRedisKeyByPattern = () => {

        if (!this.onValidateUserSelectionDetails()) {
            return;
        }

        let submittedObj = {};
        submittedObj.redisCacheKeyPattern = this.state.redisCacheKeyPattern.trim();
        submittedObj.limit = this.state.limit;
        submittedObj.region = this.state.region;

        this.setState({
            rows: [],
            isLoading: true,
            actionResultIndicator: "",
            actionResultMessage: "",
        });

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/redis/retrieve`,
            submittedObj,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    response.data.redisCacheResultList.forEach(data => {
                        data.redisCacheValue = this.onGridJsonFormat(data.redisCacheValue);
                    });
                    this.setState({
                        rows: response.data.redisCacheResultList,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        actionResultIndicator: "Error",
                        actionResultMessage: response.data.apiResponse.result,
                        isLoading: false,
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    };

    onValidateUserSelectionDetails = () => {
        if (this.state.redisCacheKeyPattern === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Enter The Value For Redis Cache Key",
            });
            return false;
        }
        return true;
    }

    onChangeControlRedisConfigMgmtSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onPageSizeChangedRedisConfigMgmtSelectionDetails = (e) => {
        this.setState({ paginationValQueue: e.target.value });
        this.state.gridApi.paginationSetPageSize(Number(e.target.value));
    };

    onGridReadyRedisConfigMgmtSelectionDetails = (params) => {
        if (params) {
            this.setState({ gridApi: params.api });
        }
        this.setState({
            rows: [],
            overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>'
        });
    };

    onGridJsonFormat = (param) => {
        return JSON.stringify(param, null, 2);
    };

    onHandleDeleteRedisConfigMgmtConfirm = (param) => {
        if(param.data.redisCacheKey == "ord_GBL_SYS_PAR_TBL_MAAR"){
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "This Key Cannot Be Deleted !",
            });
            return;
        }
        if (this.state.isWriteAllowed) {
            this.setState({
                showDeleteRedisConfigMgmtConfirmModal: true,
                redisKeySelection: param.data.redisCacheKey
            });
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action.",
            });
        }
    }

    onHandleDeleteRedisConfigMgmtConfirmModal = () => {
        return (
            <div>
                <Modal
                    show={this.state.showDeleteRedisConfigMgmtConfirmModal}
                    animation={true}
                    size="md"
                    className="shadow-lg border"
                >
                    <Modal.Header className="bg-dark text-white text-center py-1">
                        <Modal.Title className="text-center">
                            <h5 className="modal-header-custom">
                                Are You Sure You Want To Delete Redis Cache Key [{this.state.redisKeySelection}] ?
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="py-1 d-flex justify-content-center">
                        <div>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={this.onHandleDeleteRedisKey}
                                data-test="button-yes">
                                Yes
                            </Button>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={() =>
                                    this.setState({
                                        showDeleteRedisConfigMgmtConfirmModal: false,
                                        redisKeySelection: ""
                                    })
                                }
                                data-test="button-no">
                                No
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    onHandleDeleteRedisKey = (param) => {
        let submittedObj = {};
        submittedObj.redisCacheKey = this.state.redisKeySelection;

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/redis/delete`,
            submittedObj,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.apiResponse.result === "Delete Operation Executed Successfully !") {
                        this.setState({
                            actionResultIndicator: "OK",
                            actionResultMessage: response.data.apiResponse.result,
                        });

                        let tempArray = [...this.state.rows];
                        const index = tempArray.findIndex((row) =>
                            (row.redisCacheKey === this.state.redisKeySelection));
                        tempArray.splice(index, 2);

                        this.setState({
                            rows: tempArray
                        });

                    } else {
                        this.setState({
                            actionResultIndicator: "Error",
                            actionResultMessage: response.data.apiResponse.result,
                        });
                    }
                    this.setState({
                        showDeleteRedisConfigMgmtConfirmModal: false,
                        redisKeySelection: ""
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="REDIS MANAGEMENT"
                />

                <div className="container-fluid" data-test="">

                    {this.state.isAuthorized && (
                        <div>
                            <h4 className="card-title main-heading">
                                CONFIG REDIS MANAGEMENT
                            </h4>

                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    SEARCH REDIS KEY
                                </div>
                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label htmlFor="redisCacheKeyPattern" className="font-weight-bold">*Redis Cache Key [ For Example : ord_GBL_SYS_PAR_TBL_* ]</label>
                                                <input
                                                    type="text"
                                                    maxLength="100"
                                                    className="form-control form-control-sm"
                                                    name="redisCacheKeyPattern"
                                                    value={this.state.redisCacheKeyPattern}
                                                    data-test="input-redisCacheKeyPattern"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Redis Cache Key" />
                                            </div>

                                            <div className="col-lg">
                                                <label className="font-weight-bold">Fetch Limit</label>
                                                <select className="form-control form-control-sm"
                                                    name="limit"
                                                    value={this.state.limit}
                                                    data-test="select-fetchLimit"
                                                    onChange={
                                                        this.onChangeControlUserSelectionDetails}>
                                                    <option value="100">Select (Default 100)</option>
                                                    {this.getFetchLimitList()}
                                                </select>
                                            </div>

                                            {process.env.REACT_APP_DB_VIEW_ENV === 'DEV SYSTEM' || process.env.REACT_APP_DB_VIEW_ENV === 'QA SYSTEM' ?
                                                (
                                                    <div className="col-lg">
                                                        <label className="font-weight-bold">AWS Region</label>
                                                        <select className="form-control form-control-sm"
                                                            name="region"
                                                            value={this.state.region}
                                                            data-test="select-fetchRegion"
                                                            disabled
                                                            onChange={
                                                                this.onChangeControlUserSelectionDetails}>
                                                            <option value="US_EAST_1">US_EAST_1</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="col-lg">
                                                        <label className="font-weight-bold">AWS Region</label>
                                                        <select className="form-control form-control-sm"
                                                            name="region"
                                                            value={this.state.region}
                                                            data-test="select-fetchRegion"
                                                            onChange={
                                                                this.onChangeControlUserSelectionDetails}>
                                                            <option value="US_EAST_1">US_EAST_1</option>
                                                            {this.getFetchRegionList()}
                                                        </select>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">
                                                {this.state.isLoading ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10" disabled>
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp;LOADING...
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10"
                                                            data-test="button-search"
                                                            onClick={this.onHandleSearchRedisKeyByPattern}>
                                                            SEARCH
                                                        </button>
                                                    )}
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-clear"
                                                    onClick={this.onHandleClearRedisConfigMgmt}>
                                                    CLEAR
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-back"
                                                    onClick={this.onHandleBackToConfigQueryManagement}>
                                                    BACK TO CONFIG QUERY MANAGEMENT
                                                </button>
                                            </div>
                                            <div className="col-lg-3">
                                                {this.state.actionResultIndicator ===
                                                    "Error" && (
                                                        <div className="alert alert-danger alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                                {this.state.actionResultIndicator ===
                                                    "OK" && (
                                                        <div className="alert alert-success alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card mar-top10">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    SEARCH RESULT
                                </div>
                                <div className="card-body card-body-custom">
                                    <div className="pagination-dropdown">
                                        <label htmlFor="pagination-select-label" className="font-weight-bold">
                                            No of records per page:
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={(e) => {
                                                this.onChangeControlRedisConfigMgmtSelectionDetails(e);
                                                this.onPageSizeChangedRedisConfigMgmtSelectionDetails(e);
                                            }}
                                            value={this.state.paginationValQueue}
                                            name="paginationDropdownQueue"
                                            data-test="select-dropdown"
                                        >
                                            {this.state.paginationOption.map(
                                                (val) => {
                                                    return (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div
                                        className="ag-theme-balham"
                                        style={{ height: 500, width: "100%" }}
                                    >
                                        <AgGridReactARAD
                                            rowData={this.state.rows}
                                            columnDefs={this.state.configRedisGridcolumns}
                                            defaultColDef={this.state.defaultColumnDef}
                                            pagination={true}
                                            paginationPageSize={25}
                                            onGridReady={this.onGridReadyRedisConfigMgmtSelectionDetails}
                                            data-test="component-grid"
                                            enableCellTextSelection={true}
                                            overlayLoadingTemplate={
                                                this.state.overlayLoadingTemplateARAD
                                            }
                                            overlayNoRowsTemplate={
                                                this.state.overlayNoRowsTemplateARAD
                                            }
                                        ></AgGridReactARAD>

                                    </div>
                                </div>
                            </div>
                            {this.onHandleDeleteRedisConfigMgmtConfirmModal()}
                        </div>
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
export default withOktaAuth(ConfigManagementRedisUpdate);
