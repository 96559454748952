import React from "react";

export default function LegacyTxnDetails(props) {

    const payload = JSON.parse(props.data);

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            LEGACY TRANSACTION DETAILS
                        </h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Legacy Transaction Details
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MS Account: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.msacct}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Product Code: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.prodcd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Delay Transaction: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.delaytxn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Digital Settl. Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.dgtlstlmtind}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Tran Cancel Flag: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.txncnclflg}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Original Stage MTCN: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.orglstgmtcn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Event Time: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.evntdttm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Status Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.statnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Sub Status Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.substatnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Txn Type Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.typnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Unisys Host: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.uhost}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Clerk Id: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.clrkid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Plus Charge Code: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.pluschgcd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Rate Table: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.rttbl}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Env. First Flag: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.envfstflg}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Amex Memo: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.amxmem}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">File Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.filenum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pricing Channel: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.prcngchnl}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Purge Period: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.optpurgtm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Liberty Network ID: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.lbrtynetid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pseudo Flag: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.pseudflg}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pseudo Key: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.pseudkey}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Original CPC Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.orgcpcnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Destination CPC Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.dstcpcnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">IDestination CPC Number: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.idstcpcnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Text Msg: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.txtmsg}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Agent Auth: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.bngent}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bingo Num Error: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.bngentrer}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bingo Card Num: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.bngcrdent}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bingo Card Num Error: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.bngcrdenter}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bingo Coordinates: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.bngcordent}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bingo Coordinates Error: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.bngcordenter}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Flexible FOS: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flexfos}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Tsunami: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.tsnmi}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">AMS Notification: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.amsntf}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Payment Proc. Id: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.pymtprcssid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pay Reference Info: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.payrefinfo}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MCCCC: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mcccc}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MBQC: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mbqc}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Agnet Funds: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.magnfunds}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MO Type: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mmotyp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MO Status: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mmostat}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MO Sub Type: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mmosubtyp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MW Count: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mwcntb}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mccav: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mccav}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mcrtn: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mcrtn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdccrtn: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mdccrtn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Merrcd1: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.merrcd1}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Merrcd2: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.merrcd2}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Merrcd3: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.merrcd3}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdaddr: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mdaddr}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdcty: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mdcty}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdst: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mdst}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdzip: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mdzip}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdcntry: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mdcntry}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdphn: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mdphn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Msaddr: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.msaddr}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mscty: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mscty}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Msst: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.msst}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mszip: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mszip}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mscntry: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mscntry}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Msphn: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.msphn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mintfon: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mintfon}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mocty: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mocty}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Most: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.most}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mozip: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mozip}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mocntry: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mocntry}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Manifon: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.manifon}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mpcty: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mpcty}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mpst: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mpst}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mpzip: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mpzip}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mpcntry: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mpcntry}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Magntfon: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.magntfon}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mcallfon: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mcallfon}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Avldttm: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.avldttm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Sender SSN: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.sndrssn}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Qcdlvkey: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.qcdlvkey}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Qcnotkey: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.qcnotkey}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mpfrec: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mpfrec}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mtubflg: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mtubflg}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fxcomp: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.fxcomp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mdcrem: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mdcrem}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mstbmem: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mstbmem}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mosupagnt: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mosupagnt}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MTD: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mtd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">User ID: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.userid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Mmexprin: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.mmexprin}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fraud Status: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.frdstat}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fraud Disposition: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.frddisp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Rcvr Country Name: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.rcvrcntrynm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">ISKO City: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.iskocity}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">MTSOURCE: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.leginf?.flanm?.mtsrc}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Promo
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.promo?.ind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Issuer: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.promo?.issuer}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Phone Pin: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.promo?.phpintxtlst}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Sender
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        lylty
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Rule Id: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.lylty?.ruleid}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Redeem Type: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.lylty?.rdmtyp}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Redeem Value: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.lylty?.rdmval}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        pref
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">19 Mark. Opt-In: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.pref?.mktopt}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Share: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.pref?.pshare}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Mail: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.pref?.pmail}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Opt: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.sndr?.pref?.popt}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Receiver
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        lylty
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Rule Id: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.lylty?.ruleid}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Redeem Type: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.lylty?.rdmtyp}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Redeem Value: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.lylty?.rdmval}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                                        pref
                                                                    </div>
                                                                    <div className="card-body card-body-custom">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">19 Mark. Opt-In: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rvcr?.pref?.mktopt}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Share: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.pref?.pshare}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Mail: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.pref?.pmail}
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <span className="font-weight-bold">Privacy Opt: </span>{payload?.orderitemlst?.[0].orderitem?.legtxninf?.rcvr?.pref?.popt}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
