import { AgGridReact } from "ag-grid-react";
import React from "react";

export default function AuxilaryTxnDetails(props) {
    const payload = JSON.parse(props.data);
    console.log(payload?.orderitemlst?.[0].orderitem?.auxtxninf);

    var changelist = payload?.orderitemlst?.[0].orderitem?.auxtxninf?.chglst;
    const rowDataChangeList = changelist !== undefined && changelist !== null ? changelist : [];
    const colDefsChangeList = [
        { field: 'svccd', headerName: "service code", width: "250%" },
        { field: 'svcchg', headerName: "service charge", width: "250%" },
        { field: 'prmnum', headerName: "promo serial nbr", width: "250%" },
        { field: 'bchgtot', headerName: "base charge tot un-disc", width: "250%" },
        { field: 'tchgtot', headerName: "toll charge tot un-disc", width: "auto" },
        { field: 'dchgtot', headerName: "deliv charge tot un-disc", width: "auto" },
    ];

    return (
        <>
            <div className="container-fluid" id="mtcn-details">
                <div className="card-body">
                    <div>
                        <h4 className="card-title main-heading">
                            AUXILARY TRANSACTION DETAILS
                        </h4>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-dark text-white card-header-custom">
                                        Auxialry Info
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Send Receipt Seq: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.sndrcptseq}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pay Receipt Seq: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.payrcptseq}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Cancel Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.cnclcd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fusion Source: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.fsnsrc}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Cancel Reason Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.cnclreas}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">SMS BMIFORMAT Value: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.sformat}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">GDPR Doc Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.gdocid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">SMS Vendor: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.vendor}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Bill Pay MCOMMRNA: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.rnacomm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">RTRA Transaction: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.rtratxntyp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pay City: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.pcity}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Pay Settlement Rate: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.stlmtrt}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">3rd Party Send Acc: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.sndacct3rd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Adjustment Type: </span> {payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.adjtyp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Adjustment Date Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.adjdttm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Adjustment Reason code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.adjreascd}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Ref Agent Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.refagttyp}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Original Principal: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.origprin}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Original Charges: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.origchgs}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Sender Acc: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.sndacctref}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Fiat Link Share: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.fiatls}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Sender Consumer Acc: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.sndconsacct}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Card Trace Num: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.crdtrcnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">ATM Pay Dtm: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.atmpaydttm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">ATM Terminal Id: </span> {payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.atmtermid}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">ATM Terminal Num: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.atmtermnum}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Electronic Comm Ind: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.eci}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Verification Iden: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.acs}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">TMT Alternate Phone: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.tmtaltph}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">CF Misc Time: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.cfrefdttm}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Netlink WU Acc: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.wuacct}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">Netlink VIGO: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.vgacct}
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="font-weight-bold">CUB Data: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.auxInf?.cubdata}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Change List
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div
                                                                className="ag-theme-balham ml-3 mr-3"
                                                                style={{ height: 150, width: "100%" }}
                                                            >
                                                                <AgGridReact
                                                                    rowData={rowDataChangeList}
                                                                    columnDefs={colDefsChangeList}
                                                                    enableCellTextSelection={true}
                                                                ></AgGridReact>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Tax Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.tot}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.st}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.cnty}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">City: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.city}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sec Country: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.seccnty}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sec City: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.seccity}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Locality Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.lcltyid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Product Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.prodid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Client Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.clientid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax Server Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.taxservid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax SW Version: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.taxswver}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tac DB U Version: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.taxdbuver}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.cntynm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">FIPS Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.fipscd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Out Of City: </span> {payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.ofcity}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Completeion Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.compltcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax Rate: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.taxrt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fiat Tax 1: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.ftax1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fiat Tax 2: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.ftax2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fiat Tax 3: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.ftax3}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">County sales tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.cntytx}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State sales tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.statetx}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">City sales tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.taxinf?.citytx}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Digital Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Funds Available: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.avldttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">AVS: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.avs}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CVV: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.cvv}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Email Verify Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.emaivfy}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Funding Expiry: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.fundexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Extend Response: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.rskextrsp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Post Response: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.rskpostrsp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Capture Expiry: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.captexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Digital Threshold: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.fxthrsh}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">FX Threshold: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.fxthrshval}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Lock on PA: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.lckprin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DI Exchange Rate: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.inpxr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Validate Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.validtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cruise Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.cruiseflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payment Fee: </span> {payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.pymtfee}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Receipt Notification: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.rcptntf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Merchant Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.mrchntid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WEB TMT: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.webmrchntid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fund Posting Dtm: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.postdttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cancel Data Time: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.cncldttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Apollo Billed Dtm: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.abilldttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS ACH Notification: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.achnoti}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS ACH RET Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.achretcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS ACH Action Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.achactcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS ACH Bank Tran Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.achtransid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">WS ACH Bank Tran Date: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.achtransdt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">MasterCard File Num: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dgtlinf?.mcrdfilenum}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Country Specific Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Fixed on Receive Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.forflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">IOF Reason: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.iofreas}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Vet: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.vet}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total Fee Receive: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.totfeercv}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total Tax Receive: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.tottxrcv}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refund Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.rfndrinsind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refile Payment: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.rflpayamt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cpfflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF DOB: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cpfdob}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF Full Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cpffullnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF First Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cpffstnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF Last Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cpflstnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">GWRE Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.gwrgflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cuban Affidavit Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubaaffd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cuban Affidavit Flag: </span> {payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubaaffdflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cuba Question Flags: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubaquesflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">cubart: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubart}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cuba Transaction: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubatxn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Cuba First: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.cubafsttxn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Brazil Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.brzntf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Brazil Quick Pay Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.cntryinf?.qckpayind}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Refund Refile Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reason Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.reas}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Requestor: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rflreq}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Adj. Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.adjtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Orig MTCN: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.orglmtcn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Orig Rec Date: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.orglrecdt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refile MTCN: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rflmtcn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reason Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rflpayamt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refile Rec Date: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rflrecdt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Orig Dest CPC: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.orgldestcpc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refund Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rfndtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">RTRA Term Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rtratrm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Full Refund Indi.: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.fullrfnind}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refile Redirect TXN: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.rfnrflinf?.rflrd}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Dodd Frank Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Txn Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.txnflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Language Code: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.langcd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Date Available: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.dtavl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Time Available: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.tmavl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Delay Hours: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.delayhr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QCC3 Fee: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qcc3fee}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QCC3 Tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qcc3tx}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QCC3 Amount: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qcc3amt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">ET Date Available: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.etdtavl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">ET Time Available: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.ettmavl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DRT Delay: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.drtdelay}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DLYSVC Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.rcvrtot}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CFB Phone 1: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.cfbphn1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CFB Phone 2: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.cfbphn2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CFB URL: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.cfburl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CSB Phone 1: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.csbphn1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CSB Phone 2: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.csbphn2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CSB URL: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.csburl}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Dest Country TZ: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.destcntrytz}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">State Agency Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.stagtnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QCC Attn: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qccattn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ref. Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.refnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CSC Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.cscflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE Rec Date: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qrecdt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE Rec Time: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qrectm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE EST Date: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qestdt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE EST Time: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qesttm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE Mod Delay HR: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qmodhr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">QUE Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.qflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Multi Curr Buffer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.multcurrbuf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Trace Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.trcnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.paytax}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Charge: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.paychg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Card Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.crdtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                {/* <span className="font-weight-bold">Application Aid: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.C656:C662d} */}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Verified by pin: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.verifypin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Card Entry Mode: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.crdmod}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PRIN: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.prin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PAYPRIN: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.payprin}
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">TOLLSB: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.tollsb}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CHARGESB: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.chargesb}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PCHARGEB: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.pchargeb}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">TAX1: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.tax1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">TAX2: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.tax2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">TAX3: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.tax3}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">GROSSB: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.grossb}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PRMDISC: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.prmdisc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DISPRT: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.disprt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">RCPTFXFLG: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.rcptfxflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">IDEST: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.idest}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">MTD: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.mtd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Destination Country: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.dstcntry}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Destination Country Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.dstcntrynm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Destination Currency: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.dstcurr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">ORN Transaction Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.dfinf?.orntyp}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Send Stage Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sub Region: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.subreg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.zip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pin: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.pin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ptnrtoken: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.prtnrtkn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Acct: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.acct}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ptnrattr: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.attr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Status: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.hldstat}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Sub Status: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.hldsubstat}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.hldtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Timestamp: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.hldtm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Expiry: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.hldexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SP Stage Change Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.stginf?.spchgind}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Pay Stage Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sub Region: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.subreg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Zip: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.zip}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pin: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.pin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ptnrtoken: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.prtnrtkn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Acct: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.acct}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ptnrattr: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.attr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Status: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.hldstat}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Sub Status: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.hldsubstat}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.hldtyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Timestamp: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.hldtm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Hold Expiry: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.hldexp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SP Stage Change Indicator: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.stginf?.spchgind}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Send Partner Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">FSID: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.fsid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.agtnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Third Party Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.thrdrdpid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Partner Notif.: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.prtnrntfcust}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Score: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.riskscr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Decision: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.riskdecsn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Delay to Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.delay2act}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Auto Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.send?.prtnrinf?.autoact}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Pay Partner Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">FSID: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.fsid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Agent Name: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.agtnm}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Third Party Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.thrdrdpid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Partner Notif.: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.prtnrntfcust}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Score: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.riskscr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Risk Decision: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.riskdecsn}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Delay to Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.delay2act}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Auto Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.pay?.prtnrinf?.autoact}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Inter Connect Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Ref Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.intercon?.refid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Partner Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.intercon?.ptnrId}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Country Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.intercon?.cntrId}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Brazil Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay fx: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.payfx}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refund principal: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.refprin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Tax calculation: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.taxcalc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refund vet: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.refvet}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Refund Amount: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.refamt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Total tax: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.tottax}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">CPF: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.cpf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Prinicipal usd: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.prinusd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay reason desc: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.reasdesc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">IOF: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.iof}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay vet: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.payvet}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Deposit principal: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.brzlinfo?.depositprin}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Galactic Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sender Galactic Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.sndglctcid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">3rd Party Galactic: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.glctcid3rd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Galactic Id: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.payglctcid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sender Consumer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.sndcons}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">3rd Party Consumer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.cons3rd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Payer Consumer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.paycons}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sender Whitelist: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.sndwhtlst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">3rd Party Whitelist: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.whtlst3rd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Whitelist: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.glctcinf?.paywhtlst}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="card-header bg-dark text-white card-header-custom">
                                                        Loyalty Info
                                                    </div>
                                                    <div className="card-body card-body-custom">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Sender PCP Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.scustnbr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Receiver PCP Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.rcustnbr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Transaction Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.trnTyp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">PCP Change Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.pcpchgflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Send Pay Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.sndpayflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">SH Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.shflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Mail Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.mailflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Option Flag: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.optflg}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Add Services: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.addsrvc}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Card Class: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.crdcls}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">MO Index: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.moidx}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Mo Q Response: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.moqresp}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Coll Reference Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.collrefnbr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">MO Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.motype}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Device ID: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.devid}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Freq Type: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.freqty}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Freq WU: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.freqwu}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Gender: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.gndr}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Interest: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.intrst}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Perf MD: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.prfmd}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reason 1: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.rsn1}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reason 2: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.rsn2}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">On File: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.onfile}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Language: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.lang}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Date Of Birth: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.dob}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reward Buffer Data: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.rewbufdata}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Complience Validate Data: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.compvldt}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Old Conversation Card Number: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.oldconvcrdnum}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">AP Attribute Buffer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.apattbuf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Misc Buffer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.mscbuf}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">HLD Rel: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.hldrel}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">DC Pin: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.dcpin}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Pay Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.payact}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Complience Action: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.cmplact}
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <span className="font-weight-bold">Reserve Buffer: </span>{payload?.orderitemlst?.[0].orderitem?.auxtxninf?.lyltyinf?.rsrvbuf}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}