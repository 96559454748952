
export const getHeadersGSPS = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_GSPS_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_GSPS_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_GSPS_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersOrder = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_ORDER_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_ORDER_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_ORDER_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersAgent = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_AGENT_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_AGENT_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_AGENT_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersShared = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_SHARED_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_SHARED_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_SHARED_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersShared2 = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_SHARED2_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_SHARED2_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_SHARED2_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersCustomer = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_CUSTOMER_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_CUSTOMER_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_CUSTOMER_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersProduct = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_PRODUCT_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_PRODUCT_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_PRODUCT_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersNRTAgtNW = (token, userId) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      Authorization: token,
      "userId": userId,
      "x-wu-apiKey": process.env.REACT_APP_NRT_CONFIG_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_NRT_CONFIG_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_NRT_CONFIG_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersNRTLookup = (token, userId) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      Authorization: token,
      "userId": userId,
      "x-wu-apiKey": process.env.REACT_APP_LOOKUP_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_LOOKUP_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_LOOKUP_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersNRTCbsrch = (token, userId) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      Authorization: token,
      "userId": userId,
      "x-wu-apiKey": process.env.REACT_APP_CBSRCH_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_CBSRCH_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_CBSRCH_DB_VIEW_WU_HOST,
    },
  };
};


export const getformattedDate = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return year + month + day;
  //return year + '-' + month + '-' + day
};
export const getformattedDate1 = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  //return year + month + day;
  return year + '' + month + '' + day
};
export const getEnvType = () => {
  return process.env.REACT_APP_DB_VIEW_ENV
}

export const getFormattedDateForComment = () => {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return year + "/" + month + "/" + day;
  //return year + '-' + month + '-' + day
};

export const getValidData = (val) => {
  if (val === null || val === undefined || val.trim() === "") {
    return true;
  }
  return false;
}

export const getHeadersTipLog = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_TIPLOG_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_TIPLOG_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_TIPLOG_DB_VIEW_WU_HOST,
    },
  };
};

export const getHeadersLogin = (token) => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-wu-externalRefId": process.env.REACT_APP_DB_VIEW_EXTERNAL_REF_ID,
      //Authorization: token,
      "x-wu-apiKey": process.env.REACT_APP_TIPLOG_DB_VIEW_API_KEY,
      "x-api-key": process.env.REACT_APP_TIPLOG_DB_VIEW_API_KEY,
      "x-wu-host": process.env.REACT_APP_TIPLOG_DB_VIEW_WU_HOST,
    },
  };
};

export const getTiplogData = (searchType, data) => {
  switch (searchType) {
    case 'ART':
      return data.refileAcctRule;
    case 'FPF':
      return data.fraudPrevention;
    case 'FPFC':
      return data.fraudPreC2c;
    case 'GSP':
      return data.gspParams;
    case 'GRP':
      return data.group;
    case 'QUE':
      return data.queue;
    case 'RULE':
      return data.rule;
    case 'SITE':
      return data.site;
    case 'TMNS':
      return data.tmonSystem;
    case 'TMNP':
      return data.tmonParameters;
    case 'TMNG':
      return data.tmonGlobalParams;
    case 'CCMT':
      return data.tmonOperatorId;
    case 'IRP':
      return data.irpData;
    case 'CM03':
      return data.cntryUpdate;
    case 'CM04':
      return data.cntryAcctUpdate;
    case 'CM06':
      return data.cntryExchangeRate;
    case 'CM07':
      return data.cntryFeeRate;
    case 'CM09':
      return data.cntryDeliveryAddress;
    case 'CM10':
      return data.cntryDelayDeliveryAddress;
    case 'CM12':
      return data.cntryPayoutCurtain;
    case 'CM13':
      return data.cntryCorridorRules;
    case 'CM14':
      return data.cntryOptions;
    case '0001':
      return data.login;
    case '0002':
      return data.customer;
    case '0003':
      return data.transactions;
    case '0004':
      return data.account;
    case '0005':
      return data.customerAccount;
    case 'BRMT':
      return data.businessRuleMaint;
    case 'BRPT':
      return data.businessRulePartnerEmail;
    case 'MRCH':
      return data.businessRuleMerchantId;
    case 'JQCC':
      return data.quickCollectClientInfo;
    case 'JFUS':
      return data.qcFusionInfo;
    case 'JDEV':
      return data.qcDeviceInfo;
    case 'FXDB':
      return data.fxDBMaintenance;
    case 'FXSD':
      return data.fxSpreadsMaintenance;
    case 'DQMT':
      return data.deviceQueueMtcn;
    case 'JOVP':
      return data.ovVigoPayerMaint;
    case 'FCAL':
      return data.tscFirecallMaintenance;
    case 'WFLW':
      return data.workflow;
    case 'QUEA':
      return data.queueAge;
    case 'PTNR':
      return data.partner;
    case 'WUDP':
      return data.directPayer;
    case 'ALL':
      return data.all;
    default:
      return data.all;
  }
}

export const getEstDate = () => {
  const date = new Date();
  var estDate = date.toLocaleString('en-US', { timeZone: 'America/New_York', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });

  const day = estDate.substring(3, 5);
  const month = estDate.substring(0, 2);
  const year = estDate.substring(6, 10);
  var hour = estDate.substring(12, 14);
  if (hour === '24') {
    hour = "00";
  }
  const minute = estDate.substring(15, 17);
  const sec = estDate.substring(18, 20);

  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + sec;

}
