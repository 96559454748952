import { withOktaAuth } from "@okta/okta-react";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Component } from "react";
import { Nav } from "react-bootstrap";
import Header from "../../header/header";
import { getHeadersNRTCbsrch, getHeadersNRTLookup } from "../../utility/utility";
import AuxilaryTxnDetails from "./cbview-display-auxilary-txn-details";
import LegacyTxnDetails from "./cbview-display-legacy-txn-details";
import MtcnDetails from "./cbview-display-mtcn-details";
import ReceiverAgentDetails from "./cbview-display-receiver-agent-details";
import CorridorAndFinancialDetails from "./cbview-display-receiver-corridor-financial-details";
import ReceiverDetails from "./cbview-display-receiver-details";
import ReceiverPaymentDetails from "./cbview-display-receiver-payment-details";
import ReceiverTxnProcess from "./cbview-display-receiver-txn-process";
import ReceiverTxnInfo from "./cbview-display-reciever-txn-info";
import SenderAgentDetails from "./cbview-display-sender-agent-details";
import SenderDetails from "./cbview-display-sender-details";
import SenderPaymentDetails from "./cbview-display-sender-payment-details";
import SenderTxnInfo from "./cbview-display-sender-txn-info";

class cbviewDisplayMtcnMocnDetailsUI extends Component {

    state = {
        mtcn: "",
        mocn: "",
        userId: "",
        isAuthorized: false,
        isLoading: true,
        isMtcnFound: false,
        jsonData: {},
        mtcnDetailsFlag: true,
        senderDetailsFlag: false,
        receiverDetailsFlag: false,
        receiverTxnInfoFlag: false,
        corridorAndFinancialDetailsFlag: false,
        auxilaryTxnDetailsFlag: false,
        legacyTxnDetailsFlag: false
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;

        try {

            const accessRole = jwt_decode(accessToken.value).appRoles;
            const id = jwt_decode(accessToken.value).hqintlId;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_MTUTILITY) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id.substring(0, id.indexOf("@")),
                });
            }
        } catch (error) {
            // invalid token format
        }

        const mtcn = this.props.location.state.mtcn ? this.props.location.state.mtcn : "";
        const mocn = this.props.location.state.mocn;

        if (mtcn !== null && mtcn !== '') {
            this.getNrtLookupDetails(mtcn);
        } else if (mocn !== null && mocn !== '') {
            this.getNrtCbsrchDetails(mocn);
        }
    }

    getNrtLookupDetails = (mtcn) => {
        axios.get(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_LOOKUP_SERVICE_URL}/v1/ordnrt/fetchorderitem/legacy?tenantId=WU&orderId=${mtcn}`,
            getHeadersNRTLookup(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            if (response.status === 200) {
                var itmid = response.data.orderitemlst?.[0].orderitem?.itmid;
                if (itmid !== undefined && itmid !== null && itmid !== '') {
                    this.setState({ mtcn: itmid });
                    this.setState({
                        jsonData: response.data,
                        isMtcnFound: true,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        jsonData: response.data,
                        isMtcnFound: false,
                        isLoading: false
                    });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getNrtCbsrchDetails = (mocn) => {
        let submittedObj = {};
        submittedObj.searchCriteriaRequest = {
            ctrlnumber: mocn
        };

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_CBSRCH_SERVICE_URL}/v1/ordnrt/items/search`,
            submittedObj,
            getHeadersNRTCbsrch(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            if (response.status === 200) {
                this.setState({ mtcn: response.data[0]['itmid'] });
                this.getNrtLookupDetails(this.state.mtcn)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    onHandleBackCbsearch = () => {
        this.props.history.push("/cbview-search-mtcn-mocn");
    }

    onHandleJSONCbsearch = () => {
        this.props.history.push({ pathname: "/cbview-display-mtcn-mocn-json", state: { mtcn: this.state.mtcn } });
    }

    onHandleClearDetails = () => {
        this.setState({
            mtcnDetailsFlag: false,
            senderDetailsFlag: false,
            receiverTxnInfoFlag: false,
            receiverDetailsFlag: false,
            auxilaryTxnDetailsFlag: false,
            legacyTxnDetailsFlag: false
        });
    }

    onHandleMtcnDetails = () => {
        this.onHandleClearDetails();
        this.setState({
            mtcnDetailsFlag: true
        });
    }

    onHandleSenderDetails = () => {
        this.onHandleClearDetails();
        this.setState({
            senderDetailsFlag: true
        });
    }

    onHandleReceiverDetails = () => {
        this.onHandleClearDetails();
        this.setState({
            receiverDetailsFlag: true
        });
    }

    onHandleReceiverTxnInfo = () => {
        this.onHandleClearDetails();
        this.setState({
            receiverTxnInfoFlag: true
        });
    }

    onHandleAuxilaryTxnDetails = () => {
        this.onHandleClearDetails();
        this.setState({
            auxilaryTxnDetailsFlag: true
        });
    }

    onHandleLegacyTxnDetails = () => {
        this.onHandleClearDetails();
        this.setState({
            legacyTxnDetailsFlag: true
        });
    }

    getCbviewButtons = () => {
        return (
            <div>
                <div className="my-navbar mt-5 padding-10">
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.mtcnDetailsFlag ? "selected" : ""}
                            onClick={this.onHandleMtcnDetails}
                        >
                            MTCN DETAILS
                        </Nav.Item>
                    </Nav>
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.senderDetailsFlag ? "selected" : ""}
                            onClick={this.onHandleSenderDetails}
                        >
                            SENDER DETAILS
                        </Nav.Item>
                    </Nav>
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.receiverDetailsFlag ? "selected" : ""}
                            onClick={this.onHandleReceiverDetails}
                        >
                            RECEIVER DETAILS
                        </Nav.Item>
                    </Nav>
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.receiverTxnInfoFlag ? "selected" : ""}
                            onClick={this.onHandleReceiverTxnInfo}
                        >
                            RECEIVER TXN INFO
                        </Nav.Item>
                    </Nav>
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.auxilaryTxnDetailsFlag ? "selected" : ""}
                            onClick={this.onHandleAuxilaryTxnDetails}
                        >
                            AUXILARY TXN DETAILS
                        </Nav.Item>
                    </Nav>
                    <Nav className="flex-column">
                        <Nav.Item
                            className={this.state.legacyTxnDetailsFlag ? "selected" : ""}
                            onClick={this.onHandleLegacyTxnDetails}
                        >
                            LEGACY TXN DETAILS
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        );
    };

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="CBVIEW"
                />

                <div className="container-fluid">
                    {(this.state.isAuthorized && !this.state.isLoading && this.state.isMtcnFound &&
                        (<div className="card">
                            <div className="card-body">
                                <div>

                                    <div className="row mb-3">
                                        <div className="col-sm-12 text-right">
                                            <button
                                                type="button"
                                                className="btn btn-warning mar-right10"
                                                data-test="button-search"
                                                onClick={this.onHandleBackCbsearch}>
                                                BACK TO SEARCH
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                data-test="button-search"
                                                onClick={this.onHandleJSONCbsearch}>
                                                JSON VIEW
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-2">
                                            {this.getCbviewButtons()}
                                        </div>
                                        <div className="col-lg-10">
                                            {this.state.mtcnDetailsFlag && (
                                                <MtcnDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.senderDetailsFlag && (
                                                <SenderDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.senderAgentDetailsFlag && (
                                                <SenderAgentDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.senderTxnInfoFlag && (
                                                <SenderTxnInfo data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.senderPaymentDetailsFlag && (
                                                <SenderPaymentDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.receiverDetailsFlag && (
                                                <ReceiverDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.receiverAgentDetailsFlag && (
                                                <ReceiverAgentDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.receiverTxnInfoFlag && (
                                                <ReceiverTxnInfo data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.receiverTxnProcessFlag && (
                                                <ReceiverTxnProcess data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.receiverPaymentDetailsFlag && (
                                                <ReceiverPaymentDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.corridorAndFinancialDetailsFlag && (
                                                <CorridorAndFinancialDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.auxilaryTxnDetailsFlag && (
                                                <AuxilaryTxnDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                            {this.state.legacyTxnDetailsFlag && (
                                                <LegacyTxnDetails data={JSON.stringify(this.state.jsonData)} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.isAuthorized && !this.state.isLoading && !this.state.isMtcnFound && (
                        <div>
                            <div className="row">
                                <div className="col-lg-4 mar-top10"></div>
                                <div className="col-lg-4 mar-top10">
                                    <div className="container-fluid alert alert-danger alert-padding">
                                        MTCN Not Found.
                                    </div>
                                </div>
                                <div className="col-lg-4 mar-top10"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        data-test="button-search"
                                        onClick={this.onHandleBackCbsearch}>
                                        BACK TO SEARCH
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withOktaAuth(cbviewDisplayMtcnMocnDetailsUI);