import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import Header from "../../header/header";
import { getHeadersNRTAgtNW } from "../../utility/utility";

class CreateEditNrtAgentNetwork extends Component {

    state = {
        isLoading: false,
        isAuthorized: false,
        userId: "",
        actionResultIndicator: "",
        actionResultMessage: "",
        disable: true,
        isEditAgentNetwork: false,
        agentNetwork: {},
        agentNetworkCycle: "",
        networkNumber: "",
        networkSub: "",
        networkAcctNum: "",
        routingType: "",
        otherRoutingType: "",
        routingNumOrEmail: "",
        showStoreLocation: false,
        showIntendedCntryDest: false,
        showContLine: false,
        showCashInd: false,
        show2000Ind: false,
        showGWKInd: false,
        showRevisionInd: false,
        showCmbInd: false,
        showEmxInd: false,
        showWucInd: false,
        showRdtInd: false,
        showPrdInd: false,
        activationDate: "",
    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.isEditAgentNetwork) {
            this.setState({
                isEditAgentNetwork: this.props.location.state.isEditAgentNetwork,
                agentNetworkCycle: this.props.location.state.agentNetwork.agentNetworkCycle,
                networkNumber: this.props.location.state.agentNetwork.agentNetworkInfo.networkNumber,
                networkSub: this.props.location.state.agentNetwork.agentNetworkInfo.networkSub,
                networkAcctNum: this.props.location.state.agentNetwork.agentNetworkInfo.networkAcctNum,
                routingType: ["ESL", "FTX", "FTP", "EML", "EMX"].includes(this.props.location.state.agentNetwork.agentNetworkInfo.routingType) ? this.props.location.state.agentNetwork.agentNetworkInfo.routingType : "OTHER",
                otherRoutingType: ["ESL", "FTX", "FTP", "EML", "EMX"].includes(this.props.location.state.agentNetwork.agentNetworkInfo.routingType) ? "" : this.props.location.state.agentNetwork.agentNetworkInfo.routingType,
                routingNumOrEmail: this.props.location.state.agentNetwork.agentNetworkInfo.routingNumOrEmail,
                showStoreLocation: this.props.location.state.agentNetwork.agentNetworkInfo.showStoreLocation === "Y" ? true : false,
                showIntendedCntryDest: this.props.location.state.agentNetwork.agentNetworkInfo.showIntendedCntryDest === "Y" ? true : false,
                showContLine: this.props.location.state.agentNetwork.agentNetworkInfo.showContLine === "Y" ? true : false,
                showCashInd: this.props.location.state.agentNetwork.agentNetworkInfo.showCashInd === "Y" ? true : false,
                show2000Ind: this.props.location.state.agentNetwork.agentNetworkInfo.show2000Ind === "Y" ? true : false,
                showGWKInd: this.props.location.state.agentNetwork.agentNetworkInfo.showGWKInd === "Y" ? true : false,
                showRevisionInd: this.props.location.state.agentNetwork.agentNetworkInfo.showRevisionInd === "Y" ? true : false,
                showCmbInd: this.props.location.state.agentNetwork.agentNetworkInfo.showCmbInd === "Y" ? true : false,
                showEmxInd: this.props.location.state.agentNetwork.agentNetworkInfo.showEmxInd === "Y" ? true : false,
                showWucInd: this.props.location.state.agentNetwork.agentNetworkInfo.showWucInd === "Y" ? true : false,
                showRdtInd: this.props.location.state.agentNetwork.agentNetworkInfo.showRdtInd === "Y" ? true : false,
                showPrdInd: this.props.location.state.agentNetwork.agentNetworkInfo.showPrdInd === "Y" ? true : false,
                activationDate: this.props.location.state.agentNetwork.agentNetworkInfo.activationDate,
            });
        }
        const accessToken = this.props.authState.accessToken;
        try {
            const id = jwt_decode(accessToken.value).hqintlId;
            const accessRole = jwt_decode(accessToken.value).appRoles;
            const isAuthorized = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_AGENT_NETWORK_UPDATE_ACCESS);
            this.setState({
                isAuthorized: isAuthorized,
                userId: id,
            });
        } catch (error) {
            // invalid token format
        }
    }

    getAgentCyclesList = () => {
        let agentCycles = ["0300", "1000", "1900", "2400"];
        return agentCycles.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    getAgentRoutingTypeList = () => {
        let agentRoutingType = ["ESL", "FTX", "FTP", "EML", "EMX", "OTHER"];
        return agentRoutingType.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    onChangeControlUserSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onChangeControlUserCheckboxSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value === "true" ? false : true,
        });
    };

    onChangeControlUserSelectionForRoutingDetails = (e) => {
        if (e.target.value === "OTHER") {
            this.setState({
                disable: false
            });
        } else {
            this.setState({
                disable: true
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
            otherRoutingType: "",
        });
    };

    onHandleBackToSearch = (e) => {
        this.props.history.push("/nrt-agent-network-maintenance");
    }

    onHandleClear = (e) => {
        this.setState({
            isLoading: false,
            agentNetwork: {},
            agentNetworkCycle: "",
            networkNumber: "",
            networkSub: "",
            networkAcctNum: "",
            routingType: "",
            otherRoutingType: "",
            routingNumOrEmail: "",
            showStoreLocation: false,
            showIntendedCntryDest: false,
            showContLine: false,
            showCashInd: false,
            show2000Ind: false,
            showGWKInd: false,
            showRevisionInd: false,
            showCmbInd: false,
            showEmxInd: false,
            showWucInd: false,
            showRdtInd: false,
            showPrdInd: false,
            activationDate: "",
            actionResultIndicator: "",
            actionResultMessage: "",
        });
    }

    onHandleCreateEditAgentNetwork = (e) => {
        if (!this.onValidateAgentNetworkValidation()) {
            return;
        }

        this.setState({
            actionResultIndicator: "",
            actionResultMessage: "",
        });

        let agentNetwork = {};
        agentNetwork.agentNetworkCycle = this.state.agentNetworkCycle;

        let agentNetworkInfo = {};
        agentNetworkInfo.networkNumber = this.state.networkNumber;
        agentNetworkInfo.networkSub = this.state.networkSub;
        agentNetworkInfo.networkAcctNum = this.state.networkAcctNum;
        agentNetworkInfo.routingType = this.state.routingType === "OTHER" ? this.state.otherRoutingType : this.state.routingType;
        agentNetworkInfo.routingNumOrEmail = this.state.routingNumOrEmail;
        agentNetworkInfo.showStoreLocation = this.state.showStoreLocation ? "Y" : "";
        agentNetworkInfo.showIntendedCntryDest = this.state.showIntendedCntryDest ? "Y" : "";
        agentNetworkInfo.showContLine = this.state.showContLine ? "Y" : "";
        agentNetworkInfo.showCashInd = this.state.showCashInd ? "Y" : "";
        agentNetworkInfo.show2000Ind = this.state.show2000Ind ? "Y" : "";
        agentNetworkInfo.showGWKInd = this.state.showGWKInd ? "Y" : "";
        agentNetworkInfo.showRevisionInd = this.state.showRevisionInd ? "Y" : "";
        agentNetworkInfo.showCmbInd = this.state.showCmbInd ? "Y" : "";
        agentNetworkInfo.showEmxInd = this.state.showEmxInd ? "Y" : "";
        agentNetworkInfo.showWucInd = this.state.showWucInd ? "Y" : "";
        agentNetworkInfo.showRdtInd = this.state.showRdtInd ? "Y" : "";
        agentNetworkInfo.showPrdInd = this.state.showPrdInd ? "Y" : "";
        agentNetworkInfo.activationDate = this.state.activationDate;

        agentNetwork.agentNetworkInfo = agentNetworkInfo;

        this.setState({
            isLoading: true
        });

        if (!this.state.isEditAgentNetwork) {
            axios.post(
                `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_SERVICE_URL}/v1/ordnrt/dbview/agentnetwork/create`,
                agentNetwork,
                getHeadersNRTAgtNW(this.props.authState != null ? this.props.authState.accessToken.value : "", this.state.userId)
            ).then((response) => {
                if (response.status === 200) {
                    this.onHandleClear();
                    if (response.data.result === "Agent Network Created Successfully !") {
                        this.setState({
                            actionResultIndicator: "OK",
                            actionResultMessage: response.data.result,
                            isLoading: false
                        });
                    } else if (response.data.result.includes("Agent Network ID / Routing Number Already Exist In Cycle")) {
                        this.setState({
                            actionResultIndicator: "Error",
                            actionResultMessage: response.data.result,
                            isLoading: false
                        });
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            axios.put(
                `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_SERVICE_URL}/v1/ordnrt/dbview/agentnetwork/update`,
                agentNetwork,
                getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
            ).then((response) => {
                if (response.status === 200) {
                    // this.onHandleClear();
                    if (response.data.result === "Agent Network Updated Successfully !") {
                        this.setState({
                            actionResultIndicator: "OK",
                            actionResultMessage: response.data.result,
                            isLoading: false
                        });
                    } else if (response.data.result === "No Network Found For Updation !") {
                        this.setState({
                            actionResultIndicator: "Error",
                            actionResultMessage: response.data.result,
                            isLoading: false
                        });
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    onValidateAgentNetworkValidation = (e) => {

        if (this.state.agentNetworkCycle === "" || this.state.networkNumber === ""
            || this.state.networkAcctNum === "" || this.state.routingType === ""
            || this.state.routingNumOrEmail === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Enter Mandatory Fields !",
            });
            return false;
        }
        if (this.state.routingType === "OTHER" && this.state.otherRoutingType === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Enter Other Routing Type !",
            });
            return false;
        }
        if (this.state.activationDate !== "" && new Date() > new Date(this.state.activationDate)) {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Select Future Date !",
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="NRT AGENT NETWORK MAINTENANCE"
                />

                <div className="container-fluid" data-test="">
                    {this.state.isAuthorized && (
                        <div>
                            <h4 className="card-title main-heading">
                                {this.state.isEditAgentNetwork && (
                                    <span>NRT UPDATE AGENT NETWORK</span>
                                )}
                                {!this.state.isEditAgentNetwork && (
                                    <span>NRT CREATE AGENT NETWORK</span>
                                )}
                            </h4>

                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    {this.state.isEditAgentNetwork && (
                                        <span>UPDATE AGENT NETWORK</span>
                                    )}
                                    {!this.state.isEditAgentNetwork && (
                                        <span>CREATE AGENT NETWORK</span>
                                    )}
                                </div>
                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <label htmlFor="agentCycle" className="font-weight-bold">*Agent Cycle</label>
                                                <select className="form-control form-control-sm"
                                                    disabled={this.state.isEditAgentNetwork}
                                                    name="agentNetworkCycle"
                                                    value={this.state.agentNetworkCycle}
                                                    data-test="select-agentNetworkCycle"
                                                    onChange={this.onChangeControlUserSelectionDetails}>
                                                    <option value="">Select</option>
                                                    {this.getAgentCyclesList()}
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="networkNumber" className="font-weight-bold">*Network ID</label>
                                                <input
                                                    disabled={this.state.isEditAgentNetwork}
                                                    type="text"
                                                    maxLength="5"
                                                    className="form-control form-control-sm"
                                                    name="networkNumber"
                                                    value={this.state.networkNumber}
                                                    data-test="input-networkNumber"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Network ID" />
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="networkAcctNum" className="font-weight-bold">*Network Country</label>
                                                <input
                                                    disabled={this.state.isEditAgentNetwork}
                                                    type="text"
                                                    maxLength="12"
                                                    className="form-control form-control-sm"
                                                    name="networkAcctNum"
                                                    value={this.state.networkAcctNum}
                                                    data-test="input-networkAcctNum"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Network Country" />
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="routingType" className="font-weight-bold">*Routing Type</label>
                                                <select className="form-control form-control-sm"
                                                    disabled={this.state.isEditAgentNetwork}
                                                    name="routingType"
                                                    value={this.state.routingType}
                                                    data-test="select-routingType"
                                                    onChange={this.onChangeControlUserSelectionForRoutingDetails}>
                                                    <option value="">Select</option>
                                                    {this.getAgentRoutingTypeList()}
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="otherRoutingType" className="font-weight-bold">Other Routing Type</label>
                                                <input
                                                    disabled={this.state.isEditAgentNetwork || this.state.disable}
                                                    type="text"
                                                    maxLength="3"
                                                    className="form-control form-control-sm"
                                                    name="otherRoutingType"
                                                    value={this.state.otherRoutingType}
                                                    data-test="input-otherRoutingType"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Other Routing Type" />
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="routingNumOrEmail" className="font-weight-bold">*Routing Number / Email</label>
                                                <input
                                                    disabled={this.state.isEditAgentNetwork}
                                                    type="text"
                                                    maxLength="36"
                                                    className="form-control form-control-sm"
                                                    name="routingNumOrEmail"
                                                    value={this.state.routingNumOrEmail}
                                                    data-test="input-routingNumOrEmail"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Routing Number / Email" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <label htmlFor="activationDate" className="font-weight-bold">Activation Date</label>
                                                <input
                                                    disabled={this.state.isEditAgentNetwork}
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name="activationDate"
                                                    value={this.state.activationDate}
                                                    data-test="input-activationDate"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Activation Date" />
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showStoreLocation" className="font-weight-bold">Show Store Location</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showStoreLocation"
                                                        value={this.state.showStoreLocation}
                                                        data-test="input-checkbox-showStoreLocation"
                                                        checked={this.state.showStoreLocation}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showIntendedCntryDest" className="font-weight-bold">Show Intended Cntry Dest</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showIntendedCntryDest"
                                                        value={this.state.showIntendedCntryDest}
                                                        checked={this.state.showIntendedCntryDest}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showContLine" className="font-weight-bold">Show Cont Line</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showContLine"
                                                        value={this.state.showContLine}
                                                        checked={this.state.showContLine}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showCashInd" className="font-weight-bold">Show Cash Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showCashInd"
                                                        value={this.state.showCashInd}
                                                        checked={this.state.showCashInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="show2000Ind" className="font-weight-bold">Show 2000 Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="show2000Ind"
                                                        value={this.state.show2000Ind}
                                                        checked={this.state.show2000Ind}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <label htmlFor="showGWKInd" className="font-weight-bold">Show GWK Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showGWKInd"
                                                        value={this.state.showGWKInd}
                                                        checked={this.state.showGWKInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showRevisionInd" className="font-weight-bold">Show Revision Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showRevisionInd"
                                                        value={this.state.showRevisionInd}
                                                        checked={this.state.showRevisionInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showCmbInd" className="font-weight-bold">Show CMB Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showCmbInd"
                                                        value={this.state.showCmbInd}
                                                        checked={this.state.showCmbInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showEmxInd" className="font-weight-bold">Show EMX Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showEmxInd"
                                                        value={this.state.showEmxInd}
                                                        checked={this.state.showEmxInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showWucInd" className="font-weight-bold">Show WUC Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showWucInd"
                                                        value={this.state.showWucInd}
                                                        checked={this.state.showWucInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <label htmlFor="showRdtInd" className="font-weight-bold">Show RDT Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showRdtInd"
                                                        value={this.state.showRdtInd}
                                                        checked={this.state.showRdtInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-2">
                                                <label htmlFor="showPrdInd" className="font-weight-bold">Show PRD Ind</label>
                                                <div>
                                                    <input
                                                        className="input-checkbox-style"
                                                        type="checkbox"
                                                        name="showPrdInd"
                                                        value={this.state.showPrdInd}
                                                        checked={this.state.showPrdInd}
                                                        onChange={this.onChangeControlUserCheckboxSelectionDetails}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">

                                                {this.state.isLoading ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10" disabled>
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp;LOADING...
                                                        </button>
                                                    ) : (
                                                        <span>
                                                            {!this.state.isEditAgentNetwork && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-warning mar-right10"
                                                                    data-test="button-submit"
                                                                    onClick={this.onHandleCreateEditAgentNetwork}>
                                                                    SUBMIT
                                                                </button>
                                                            )}
                                                            {this.state.isEditAgentNetwork && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-warning mar-right10"
                                                                    data-test="button-submit"
                                                                    onClick={this.onHandleCreateEditAgentNetwork}>
                                                                    SUBMIT
                                                                </button>
                                                            )}
                                                        </span>
                                                    )}

                                                {!this.state.isEditAgentNetwork && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning mar-right10"
                                                        data-test="button-clear"
                                                        onClick={this.onHandleClear}>
                                                        CLEAR
                                                    </button>
                                                )}
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-back"
                                                    onClick={this.onHandleBackToSearch}>
                                                    BACK TO SEARCH
                                                </button>
                                            </div>
                                            <div className="col-lg-3">
                                                {this.state.actionResultIndicator ===
                                                    "Error" && (
                                                        <div className="alert alert-danger alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                                {this.state.actionResultIndicator ===
                                                    "OK" && (
                                                        <div className="alert alert-success alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    }
}

export default withOktaAuth(CreateEditNrtAgentNetwork);